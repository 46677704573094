<template>
    <div class="xl:!tw-hidden">
        <Top title="Leituras" :notifications="!userStore.isTechnician" />
    </div>
    <v-container class="flex-column justify-start tw-flex tw-gap-4 xl:!tw-pt-9 xl:!tw-px-16">
        <h1 class="!tw-hidden xl:!tw-block tw-mr-auto tw-text-2xl tw-font-bold">Leituras</h1>
        <v-row align="center" justify="center" class="xl:!tw-hidden fill-width h-auto justify-start align-content-start">
            <v-col :cols="addReading ? '5' : '6'">
                <v-btn @click="changeView('do')" block variant="tonal" size="large" rounded="xl" :class="activeComponentComp == 'do' ? 'bg-primary_light' : 'bg-gray_light'" class="tw-mt-4 text-none"
                    >A realizar</v-btn
                >
            </v-col>
            <v-col :cols="addReading ? '5' : '6'">
                <v-btn
                    @click="changeView('finished')"
                    block
                    variant="tonal"
                    size="large"
                    rounded="xl"
                    :class="activeComponentComp == 'finished' ? 'bg-primary_light' : 'bg-gray_light'"
                    class="tw-mt-4 text-none bg-primary_light"
                    >Realizadas</v-btn
                >
            </v-col>
            <v-col cols="2" v-if="addReading">
                <v-btn @click="isActive = true" block variant="tonal" size="large" rounded="xl" class="tw-mt-4 text-none bg-gray_light"><v-icon class="tw-text-primary">mdi-plus</v-icon></v-btn>
            </v-col>
        </v-row>
        <template v-if="activeComponent == 'do'">
            <v-row>
                <v-col class="!tw-pt-0" cols="12" xl="5">
                    <v-select
                        hide-details="auto"
                        clearable
                        variant="outlined"
                        placeholder="Todas as infraestrutura"
                        item-value="id"
                        item-title="name"
                        density="comfortable"
                        @update:model-value="setInfrastructureId"
                        :items="infrastructures"></v-select>
                </v-col>
            </v-row>
            <div class="!tw-hidden xl:!tw-block tw-mt-4">
                <v-row class="fill-width h-auto justify-start align-content-start">
                    <v-col cols="3">
                        <v-btn
                            @click="changeView('do')"
                            variant="tonal"
                            size="large"
                            rounded="xl"
                            :class="activeComponentComp == 'do' ? 'bg-primary_light' : 'bg-gray_light'"
                            class="text-none tw-w-full"
                            >A realizar</v-btn
                        >
                    </v-col>
                    <v-col cols="3">
                        <v-btn
                            @click="changeView('finished')"
                            variant="tonal"
                            size="large"
                            rounded="xl"
                            :class="activeComponentComp == 'finished' ? 'bg-primary_light' : 'bg-gray_light'"
                            class="text-none bg-primary_light tw-w-full"
                            >Realizadas</v-btn
                        >
                    </v-col>
                    <v-col offset="3" class="tw-text-end" v-if="addReading">
                        <v-btn @click="isActive = true" variant="outlined" size="large" rounded="xl" class="text-none bg-white" append-icon="mdi-plus">Adicionar</v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-for="(obj, date) in readingsGrouped" :key="date">
                <h4 class="tw-text-primary tw-font-semibold tw-text-sm tw-mb-3">{{ date }}</h4>
                <div class="tw-flex tw-flex-col tw-gap-4">
                    <ReadingCard
                        create
                        v-for="(reading, i) in obj"
                        :key="i"
                        :reading="reading"
                        v-intersect.quiet="isLastReading(reading.id) && onIntersectReadingSets"
                        @click="goToCreateReading(reading)" />
                </div>
            </div>
            <p v-if="readingSets.readings.length == 0" class="tw-text-center tw-text-gray_darker">Sem resultados</p>
            <div></div>
        </template>
        <template v-if="activeComponent == 'finished'">
            <v-row>
                <v-col class="!tw-pt-0" cols="12" xl="5">
                    <Datepicker @update:model-value="setDate" v-model="date" range :enable-time-picker="false" placeholder="Selecionar intervalo de datas" />
                </v-col>
            </v-row>
            <div class="!tw-hidden xl:!tw-block tw-mt-4">
                <v-row class="fill-width h-auto justify-start align-content-start">
                    <v-col cols="3">
                        <v-btn
                            @click="changeView('do')"
                            variant="tonal"
                            size="large"
                            rounded="xl"
                            :class="activeComponentComp == 'do' ? 'bg-primary_light' : 'bg-gray_light'"
                            class="text-none tw-w-full"
                            >A realizar</v-btn
                        >
                    </v-col>
                    <v-col cols="3">
                        <v-btn
                            @click="changeView('finished')"
                            variant="tonal"
                            size="large"
                            rounded="xl"
                            :class="activeComponentComp == 'finished' ? 'bg-primary_light' : 'bg-gray_light'"
                            class="text-none bg-primary_light tw-w-full"
                            >Realizadas</v-btn
                        >
                    </v-col>
                    <v-col offset="3" class="tw-text-end" v-if="addReading">
                        <v-btn @click="isActive = true" variant="outlined" size="large" rounded="xl" class="text-none bg-white" append-icon="mdi-plus">Adicionar</v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-for="(obj, date) in readingsFinishedGrouped" :key="date">
                <div class="tw-flex tw-justify-between tw-items-center tw-text-sm tw-mb-3">
                    <h4 class="tw-text-primary tw-font-semibold">{{ date }}</h4>
                </div>

                <div class="tw-flex tw-flex-col tw-gap-4">
                    <ReadingCard
                        :create="false"
                        v-for="(reading, i) in obj"
                        :key="i"
                        :reading="reading"
                        v-intersect.quiet="isLastReadingFinished(reading.id) && onIntersectReadingsFinished"
                        @click="goToReading(reading)" />
                </div>
            </div>

            <p v-if="readingsFinished.readings.length == 0" class="tw-text-center tw-text-gray_darker">Sem resultados</p>
            <div v-intersect="onIntersectReadingsFinished"></div>
        </template>
        <v-dialog max-width="500" v-model="isActive">
            <template v-if="isActive">
                <v-card title="Nova leitura">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <!-- select with networks -->
                                <v-select
                                    v-model="selectedNetwork"
                                    hide-details="auto"
                                    clearable
                                    variant="outlined"
                                    placeholder="Selecionar rede"
                                    item-value="id"
                                    item-title="name"
                                    density="compact"
                                    :items="networkToAddReadingLegionella"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <!-- select with control points -->
                                <v-select
                                    :disabled="selectedNetwork == null"
                                    v-model="selectedControlPoint"
                                    hide-details="auto"
                                    clearable
                                    variant="outlined"
                                    placeholder="Selecionar ponto de controlo"
                                    item-value="id"
                                    item-title="name"
                                    density="compact"
                                    :items="controlPointsToSelect"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text="Adicionar" @click="addReadingSet = true"></v-btn>
                        <v-btn text="Fechar" @click="isActive = false"></v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
    <Reading v-if="openReadingSidebar" v-model="openReadingSidebar" :readingId="readingId" />
    <Create v-if="openCreateReadingSidebar" v-model="openCreateReadingSidebar" :readingId="readingId" />
</template>

<script setup lang="ts">
    // imports
    import { computed, ref, onMounted, watch } from 'vue';
    import Top from '../Layout/Top.vue';
    import ReadingCard from './ReadingCard.vue';
    import Reading from './Reading.vue';
    import Create from './Create.vue';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import _ from 'lodash';
    import Datepicker from '@vuepic/vue-datepicker';
    import moment from 'moment';

    import { useRoute, useRouter } from 'vue-router';
    import { useUserStore } from '@/store/user';
    import { useAppStore } from '@/store/app';

    const userStore = useUserStore();
    const appStore = useAppStore();

    const isActive = ref(false);
    const selectedNetwork = ref(null);
    const selectedControlPoint = ref(null);
    const addReadingSet = ref(false);

    const openReadingSidebar = ref(false);
    const openCreateReadingSidebar = ref(false);
    const readingId = ref(null);

    watch(isActive, (value) => {
        if (value) {
            // reset values
            selectedNetwork.value = null;
            selectedControlPoint.value = null;
        }
    });
    watch(selectedNetwork, async (value) => {
        if (value) {
            selectedControlPoint.value = null;
            const res = await axios.get(`/water-networks?${qs.stringify(queryWaterNetworks.value)}`);
            //console.log(res);
            controlPointsToSelect.value = res.data.data[0].control_points;
        }
    });

    watch(addReadingSet, async (value) => {
        if (value) {
            const res = await axios.post(`/reading-sets`, {
                data: {
                    control_point: selectedControlPoint.value,
                    date: moment().format('YYYY-MM-DD'),
                    reading_type: 'microbiológica-legionella',
                },
            });

            // force reload page using router
            $router.go(0);
        }
    });

    // data
    const activeComponent = ref('do');
    const infrastructures = ref([]);
    const readingSets = ref({ readings: [], pagination: {} });
    const readingsFinished = ref({ readings: [], pagination: {} });
    const readingsFinishedLegionella = ref({ readings: [], pagination: {} });
    const hasMounted = ref(false);
    const $router = useRouter();
    const $route = useRoute();

    const addReading = ref(false);
    const today = moment().format('DD/MM/YYYY');
    const controlPointsToNotAddReadingLegionella = ref([]);
    const networkToAddReadingLegionella = ref([]);
    const controlPointsToSelect = ref([]);

    const date = ref();

    const queryWaterNetworks = ref({
        populate: {
            control_points: {
                filters: {
                    id: {
                        $notIn: controlPointsToNotAddReadingLegionella.value,
                    },
                },
            },
            infrastructure: {
                populate: ['client', 'users'],
            },
        },
        filters: {
            id: selectedNetwork,
            ...(userStore.isTechnician
                ? {
                      infrastructure: {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      },
                  }
                : {
                      infrastructure: {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client.id,
                              },
                          },
                      },
                  }),
        },
        pagination: {
            page: 1,
            pageSize: 25,
        },
    });

    const queryReadingSets = ref({
        populate: [
            'control_point.water_network.infrastructure.picture',
            'control_point.water_network.infrastructure',
            'control_point.water_network.infrastructure.client',
            'control_point.water_network',
        ],
        filters: {
            readings: {
                id: {
                    $null: true,
                },
            },
            date: {
                $lte: moment().toISOString(),
            },
            ...(userStore.isTechnician
                ? {
                      control_point: {
                          water_network: {
                              infrastructure: {
                                  users: {
                                      id: {
                                          $eq: userStore.getUser.id,
                                      },
                                  },
                              },
                          },
                      },
                  }
                : {
                      control_point: {
                          water_network: {
                              infrastructure: {
                                  client: {
                                      id: {
                                          $eq: userStore.getUser.client.id,
                                      },
                                  },
                              },
                          },
                      },
                  }),
        },
        pagination: {
            page: 1,
            pageSize: 25,
        },
        sort: ['date:asc', 'control_point.water_network.infrastructure.name:asc', 'control_point.water_network.name:asc', 'control_point.name:asc'],
    });

    const queryReadingSetsFinished = ref({
        populate: [
            'control_point.water_network.infrastructure.picture',
            'control_point.water_network.infrastructure',
            'control_point.water_network.infrastructure.client',
            'control_point.water_network',
        ],
        filters: {
            readings: {
                id: {
                    $null: false,
                },
            },
            ...(userStore.isTechnician
                ? {
                      control_point: {
                          water_network: {
                              infrastructure: {
                                  users: {
                                      id: {
                                          $eq: userStore.getUser.id,
                                      },
                                  },
                              },
                          },
                      },
                  }
                : {
                      control_point: {
                          water_network: {
                              infrastructure: {
                                  client: {
                                      id: {
                                          $eq: userStore.getUser.client.id,
                                      },
                                  },
                              },
                          },
                      },
                  }),
        },
        pagination: {
            page: 1,
            pageSize: 25,
        },
        sort: ['date:desc', 'control_point.water_network.infrastructure.name:asc', 'control_point.water_network.name:asc', 'control_point.name:asc'],
    });

    const queryReadingSetsFinishedLegionella = ref({
        populate: [
            'control_point.water_network.infrastructure.picture',
            'control_point.water_network.infrastructure',
            'control_point.water_network.infrastructure.client',
            'control_point.water_network',
        ],
        filters: {
            // readings: {
            //     id: {
            //         $null: false,
            //     },
            // },
            reading_type: {
                $eq: 'microbiológica-legionella',
            },
            date: {
                // get only from today
                $gte: moment().startOf('day').format('YYYY-MM-DD'),
                $lte: moment().endOf('day').format('YYYY-MM-DD'),
            },
            ...(userStore.isTechnician
                ? {
                      control_point: {
                          water_network: {
                              infrastructure: {
                                  users: {
                                      id: {
                                          $eq: userStore.getUser.id,
                                      },
                                  },
                              },
                          },
                      },
                  }
                : {
                      control_point: {
                          water_network: {
                              infrastructure: {
                                  client: {
                                      id: {
                                          $eq: userStore.getUser.client.id,
                                      },
                                  },
                              },
                          },
                      },
                  }),
        },
        pagination: {
            page: 1,
            pageSize: 25,
        },
        sort: ['date:desc', 'control_point.water_network.infrastructure.name:asc', 'control_point.water_network.name:asc', 'control_point.name:asc'],
    });

    const activeComponentComp = computed(() => {
        return activeComponent.value;
    });

    // methods
    const changeView = (view: string) => {
        activeComponent.value = view;
        $router.push({ query: { view: view } });
    };

    const getInfrastructures = async () => {
        const query = {
            filters: {
                ...(userStore.isTechnician
                    ? {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      }
                    : {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client.id,
                              },
                          },
                      }),
            },
            sort: ['name:asc'],
            pagination: {
                page: 1,
                pageSize: 1000,
            },
        };
        const res = await axios.get(`/infrastructures?${qs.stringify(query)}`);
        infrastructures.value = res.data.data;
    };

    const getReadingSets = async () => {
        const res = await axios.get(`/reading-sets?${qs.stringify(queryReadingSets.value)}`);

        readingSets.value.readings = readingSets.value.pagination.page == 1 ? res.data.data : [...readingSets.value.readings, ...res.data.data];
        queryReadingSets.value.pagination = res.data.meta.pagination;
    };

    const getReadingsFinished = async () => {
        const res = await axios.get(`/reading-sets?${qs.stringify(queryReadingSetsFinished.value)}`);
        readingsFinished.value.readings = readingsFinished.value.pagination.page == 1 ? res.data.data : [...readingsFinished.value.readings, ...res.data.data];
        readingsFinished.value.pagination = res.data.meta.pagination;
    };

    const getReadingFinishedLegionella = async () => {
        const res = await axios.get(`/reading-sets?${qs.stringify(queryReadingSetsFinishedLegionella.value)}`);
        readingsFinishedLegionella.value.readings = readingsFinishedLegionella.value.pagination.page == 1 ? res.data.data : [...readingsFinishedLegionella.value.readings, ...res.data.data];
        readingsFinishedLegionella.value.pagination = res.data.meta.pagination;

        // for all readings, check if there is 2 legionella readings from the same water network, if true then enable + icon
        const readings = res.data.data;
        const grouped = _.groupBy(readings, (reading: any) => {
            return reading.control_point.water_network.id;
        });

        for (const key in grouped) {
            if (grouped[key].length < 3) {
                let queryWaterNetworksAux = ref({
                    populate: {
                        control_points: {
                            filters: {
                                id: {
                                    $notIn: grouped[key].map((reading: any) => reading.control_point.id),
                                },
                            },
                        },
                        infrastructure: {
                            populate: ['client', 'users'],
                        },
                    },
                    filters: {
                        id: grouped[key][0].control_point.water_network.id,
                        ...(userStore.isTechnician
                            ? {
                                  infrastructure: {
                                      users: {
                                          id: {
                                              $eq: userStore.getUser.id,
                                          },
                                      },
                                  },
                              }
                            : {
                                  infrastructure: {
                                      client: {
                                          id: {
                                              $eq: userStore.getUser.client.id,
                                          },
                                      },
                                  },
                              }),
                    },
                    pagination: {
                        page: 1,
                        pageSize: 25,
                    },
                });
                const res = await axios.get(`/water-networks?${qs.stringify(queryWaterNetworksAux.value)}`);
                //console.log(res);

                if (res.data.data[0].control_points.length > 0) {
                    addReading.value = true;
                    networkToAddReadingLegionella.value.push({ id: grouped[key][0].control_point.water_network.id, name: grouped[key][0].control_point.water_network.name });
                    let elems = grouped[key].map((reading: any) => reading.control_point.id);
                    // for each in elems, add to controlPointsToNotAddReadingLegionella
                    elems.forEach((elem: any) => {
                        controlPointsToNotAddReadingLegionella.value.push(elem);
                    });
                }
            }
        }
    };

    const onIntersectReadingSets = (isIntersecting: any, entries: any, observer: any) => {
        if (isIntersecting && queryReadingSets.value.pagination.page < queryReadingSets.value.pagination.pageCount) {
            queryReadingSets.value.pagination.page++;
            getReadingSets();
        }
    };

    const onIntersectReadingsFinished = (isIntersecting: any, entries: any, observer: any) => {
        if (isIntersecting && readingsFinished.value.pagination.page < readingsFinished.value.pagination.pageCount) {
            queryReadingSetsFinished.value.pagination.page++;
            getReadingsFinished();
        }
    };

    function isLastReading(id: number) {
        return id == readingSets.value.readings[readingSets.value.readings.length - 1].id;
    }

    function isLastReadingFinished(id: number) {
        return id == readingsFinished.value.readings[readingsFinished.value.readings.length - 1].id;
    }

    async function setDate(value: any) {
        if (value == null) {
            _.unset(queryReadingSetsFinished.value, 'filters.date.$gte');
            _.unset(queryReadingSetsFinished.value, 'filters.date.$lte');
        } else {
            _.set(queryReadingSetsFinished.value, 'filters.date.$gte', moment(value[0]).format('YYYY-MM-DD'));
            _.set(queryReadingSetsFinished.value, 'filters.date.$lte', moment(value[1]).format('YYYY-MM-DD'));
        }

        readingsFinished.value.readings = [];
        readingsFinished.value.pagination = {};
        _.set(queryReadingSetsFinished.value, 'pagination.page', 1);

        getReadingsFinished();
    }

    function setInfrastructureId(value: any) {
        if (!value) {
            _.unset(queryReadingSets.value, 'filters.control_point.water_network.infrastructure.id');
        } else {
            _.set(queryReadingSets.value, 'filters.control_point.water_network.infrastructure.id', value);
        }

        readingSets.value.pagination = {};
        readingSets.value.readings = [];
        _.set(queryReadingSets.value, 'pagination.page', 1);
        getReadingSets();
    }

    const goToReading = (reading: any) => {
        if (isMobile.value) {
            $router.push({ name: 'Reading', params: { id: reading.id } });
        } else {
            // Close the create reading sidebar if it's open
            openCreateReadingSidebar.value = false;
            // If the sidebar is already open, simply update the reading info
            if (openReadingSidebar.value) {
                readingId.value = reading.id;
            } else {
                // If it's not open, open the sidebar and update the reading info
                openReadingSidebar.value = true;
                readingId.value = reading.id;
            }
        }
    };

    const goToCreateReading = (reading: any) => {
        if (isMobile.value) {
            $router.push({ name: 'CreateReading', params: { id: reading.id } });
        } else {
            // Close the reading sidebar if it's open
            openReadingSidebar.value = false;
            // If the sidebar is already open, simply update the create reading info
            if (openCreateReadingSidebar.value) {
                readingId.value = reading.id;
            } else {
                // If it's not open, open the sidebar and update the reading info
                openCreateReadingSidebar.value = true;
                readingId.value = reading.id;
            }
        }
    };

    const isMobile = computed(() => {
        return appStore.isMobile;
    });

    const readingsFinishedGrouped = computed(() => {
        return _.groupBy(readingsFinished.value.readings, (reading: any) => {
            return moment(reading.date).format('DD/MM/YYYY');
        });
    });

    const readingsGrouped = computed(() => {
        return _.groupBy(readingSets.value.readings, (reading: any) => {
            return moment(reading.date).format('DD/MM/YYYY');
        });
    });

    onMounted(async () => {
        if ($route.query.view) {
            activeComponent.value = $route.query.view as string;
        }

        await getInfrastructures();
        await getReadingSets();
        hasMounted.value = true;
        await getReadingsFinished();
        await getReadingFinishedLegionella();
    });
</script>

<style scoped></style>
