<template>
    <div class="xl:!tw-hidden">
        <Top title="Inspeções" :notifications="!userStore.isTechnician" />
    </div>
    <v-container class="flex-column justify-start tw-flex tw-gap-4 xl:!tw-pt-9 xl:!tw-px-16">
        <h1 class="!tw-hidden xl:!tw-block tw-mr-auto tw-text-2xl tw-font-bold">Inspeções</h1>
        <v-row class="fill-width h-auto justify-start align-content-start">
            <v-col cols="6" xl="3">
                <v-btn @click="changeView('do')" block variant="tonal" size="large" rounded="xl" :class="activeComponentComp == 'do' ? 'bg-primary_light' : 'bg-gray_light'" class="tw-mt-4 text-none"
                    >A realizar</v-btn
                >
            </v-col>
            <v-col cols="6" xl="3">
                <v-btn
                    @click="changeView('finished')"
                    block
                    variant="tonal"
                    size="large"
                    rounded="xl"
                    :class="activeComponentComp == 'finished' ? 'bg-primary_light' : 'bg-gray_light'"
                    class="tw-mt-4 text-none bg-primary_light"
                    >Realizadas</v-btn
                >
            </v-col>
            <v-col offset="3" class="!tw-hidden xl:!tw-block tw-text-end" v-if="me.client.users.length < me.client.max_technicians">
                <v-btn @click="goToSchedule" variant="outlined" size="large" rounded="xl" class="tw-mt-4 bg-white text-none" append-icon="mdi-plus">Agendar</v-btn>
            </v-col>
        </v-row>
        <template v-if="activeComponent == 'do'">
            <template v-if="notCompletedInspections.inspections && notCompletedInspections.inspections.length > 0">
                <div class="tw-flex tw-flex-col tw-gap-3">
                    <InspectionCard v-for="(inspection, i) in notCompletedInspections.inspections" :key="i" :inspection="inspection" @click="goToInspection(inspection)" />
                </div>
                <div v-intersect="onIntersectNotCompletedInspections"></div>
            </template>
            <template v-else>
                <div class="tw-flex tw-flex-col tw-gap-3 tw-text-center tw-items-center">
                    <p>Não existem inspeções a realizar!</p>
                </div>
            </template>
        </template>
        <template v-if="activeComponent == 'finished'">
            <template v-if="completedInspections.inspections && completedInspections.inspections.length > 0">
                <div class="tw-mt-4 tw-flex tw-flex-col tw-gap-3">
                    <InspectionCard v-for="(inspection, i) in completedInspections.inspections" :key="i" :inspection="inspection" @click="goToInspection(inspection)" />
                </div>

                <div v-intersect="onIntersectCompletedInspections"></div>
            </template>
            <template v-else>
                <div class="tw-flex tw-flex-col tw-gap-3 tw-text-center tw-items-center">
                    <p>Não existem inspeções realizadas!</p>
                </div>
            </template>
        </template>
    </v-container>
    <Schedule v-if="openScheduleSidebar" v-model="openScheduleSidebar" :inspectionId="inspectionId" />
    <Inspection v-if="openInspectionSidebar" v-model="openInspectionSidebar" :inspectionId="inspectionId" />
</template>

<script setup lang="ts">
    // imports
    import { computed, ref, onMounted } from 'vue';
    import Top from '../Layout/Top.vue';
    import Inspection from './Inspection.vue';
    import InspectionCard from './InspectionCard.vue';
    import Schedule from './Schedule.vue';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import { useRoute, useRouter } from 'vue-router';
    import { useUserStore } from '@/store/user';
    import { useAppStore } from '@/store/app';

    // data
    const activeComponent = ref('do');
    const $router = useRouter();
    const $route = useRoute();
    const hasMounted = ref(false);
    const completedInspections = ref({ inspections: [], pagination: {} });
    const notCompletedInspections = ref({ inspections: [], pagination: {} });

    const userStore = useUserStore();
    const appStore = useAppStore();

    const me = userStore.getUser;

    const openScheduleSidebar = ref(false);
    const openInspectionSidebar = ref(false);
    const inspectionId = ref(null);

    const isMobile = computed(() => {
        return appStore.isMobile;
    });

    const activeComponentComp = computed(() => {
        return activeComponent.value;
    });

    // methods
    const changeView = (view: string) => {
        activeComponent.value = view;
        $router.push({ query: { view: view } });
    };

    const queryCompletedInspections = ref({
        populate: ['infrastructure.picture'],
        pagination: {
            page: 1,
            pageSize: 25,
        },
        filters: {
            status: {
                $in: ['concluído', 'negado', 'cancelado'],
            },
            infrastructure: {
                ...(userStore.isTechnician
                    ? {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      }
                    : {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client.id,
                              },
                          },
                      }),
            },
        },
        sort: ['date:desc'],
    });

    const queryNotCompletedInspections = ref({
        populate: ['infrastructure.picture'],
        pagination: {
            page: 1,
            pageSize: 25,
        },
        sort: ['date:asc'],
        filters: {
            status: {
                $notIn: ['concluído', 'negado', 'cancelado'],
            },
            infrastructure: {
                ...(userStore.isTechnician
                    ? {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      }
                    : {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client.id,
                              },
                          },
                      }),
            },
        },
    });

    const getCompletedInpsections = async () => {
        const res = await axios.get(`/inspections?${qs.stringify(queryCompletedInspections.value)}`);
        res.data.data.map((inspection: any) => {
            completedInspections.value.inspections.push(inspection);
        });
        completedInspections.value.pagination = res.data.meta.pagination;
        queryCompletedInspections.value.pagination.page = res.data.meta.pagination.page;
    };

    const getNotCompletedInpsections = async () => {
        const res = await axios.get(`/inspections?${qs.stringify(queryNotCompletedInspections.value)}`);

        res.data.data.map((inspection: any) => {
            notCompletedInspections.value.inspections.push(inspection);
        });
        notCompletedInspections.value.pagination = res.data.meta.pagination;
        queryNotCompletedInspections.value.pagination.page = res.data.meta.pagination.page;
    };

    const onIntersectCompletedInspections = (isIntersecting: any, entries: any, observer: any) => {
        if (!hasMounted.value) return;
        if (isIntersecting && queryCompletedInspections.value.pagination.page < completedInspections.value.pagination.pageCount) {
            queryNotCompletedInspections.value.pagination.page++;
            getCompletedInpsections();
        }
    };

    const onIntersectNotCompletedInspections = (isIntersecting: any, entries: any, observer: any) => {
        if (isIntersecting && queryNotCompletedInspections.value.pagination.page < notCompletedInspections.value.pagination.pageCount) {
            queryNotCompletedInspections.value.pagination.page++;
            getNotCompletedInpsections();
        }
    };

    const goToInspection = (inspection: any) => {
        if (isMobile.value) {
            $router.push({ name: 'Inspection', params: { idInspection: inspection.id } });
        } else {
            // Close the schedule inspection sidebar if it's open
            openScheduleSidebar.value = false;
            // If the sidebar is already open, simply update the inspection info
            if (openInspectionSidebar.value) {
                inspectionId.value = inspection.id;
            } else {
                // If it's not open, open the sidebar and update the inspection info
                openInspectionSidebar.value = true;
                inspectionId.value = inspection.id;
            }
        }
    };

    const goToSchedule = () => {
        if (isMobile.value) {
            $router.push({ name: 'Schedule' });
        } else {
            // Close the inspection sidebar if it's open
            openInspectionSidebar.value = false;
            // If the sidebar is already open, reset the inspection info
            if (openScheduleSidebar.value) {
                inspectionId.value = null;
            } else {
                // If it's not open, open the sidebar and reset the inspection info
                openScheduleSidebar.value = true;
                inspectionId.value = null;
            }
        }
    };

    onMounted(async () => {
        if ($route.query.view) {
            activeComponent.value = $route.query.view as string;
        }

        hasMounted.value = true;
        await getCompletedInpsections();
        await getNotCompletedInpsections();
    });
</script>

<style scoped></style>
