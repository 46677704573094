<template>
    <v-card @click="goTo" color="primary_lightest" class="tw-w-full tw-overflow-initial elevation-0 rounded-lg">
        <div :class="image ? 'tw-p-3' : 'tw-p-1'" class="tw-flex tw-gap-2">
            <v-avatar v-if="image" size="56">
                <v-img cover :src="props.image" class="tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-primary_light tw-bg-opacity-30"></v-img>
            </v-avatar>
            <div v-if="imageMIME">
                <v-img width="48" cover :src="props.imageMIME" class=""></v-img>
            </div>
            <div v-if="props.inspection" class="tw-bg-primary tw-rounded-lg tw-p-2"><v-img cover width="2rem" src="../../assets/icons/calendar_inspections.svg"></v-img></div>
            <div :class="point == null && network == null ? 'tw-justify-center' : 'tw-justify-between'" class="tw-grow tw-flex tw-flex-col">
                <div :class="point && network ? '' : 'tw-max-w-[13rem]'" class="tw-items-center tw-justify-between md:tw-max-w-xl tw-flex tw-gap-x-2">
                    <p :class="point && network ? 'tw-py-0 tw-pr-0' : 'tw-max-w-[13rem] md:tw-max-w-xl'" class="tw-text-sm tw-line-clamp-2 tw-font-semibold">{{ props.title }}</p>
                    <span v-if="network" class="tw-text-xs tw-pl-2">{{ props.network }}</span>
                    <p v-if="props.inspection" :class="colorInspectionSate" class="tw-rounded-md tw-p-1 tw-text-xs tw-font-medium tw-text-white">{{ textInspectionState }}</p>
                </div>
                <div class="tw-flex tw-flex-row tw-justify-between tw-items-end tw-gap-4">
                    <div class="tw-flex tw-flex-col">
                        <span v-if="point" class="tw-text-xs">{{ props.point }}</span>
                        <span v-if="date" class="tw-text-xs"> {{ props.date }}</span>
                    </div>

                    <v-chip v-if="readingType" class="" size="small"> {{ props.readingType }}</v-chip>
                </div>
            </div>
            <v-icon v-if="props.arrow" class="tw-self-center" size="x-large" color="primary" aria-hidden="false"> mdi-chevron-right </v-icon>
            <v-icon v-if="props.download" class="tw-self-center" size="x-large" color="primary" aria-hidden="false"> mdi-file-download</v-icon>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    const $router = useRouter();

    const props = defineProps({
        title: String,
        subtitle: String,
        image: {
            type: String,
            default: null,
        },
        imageMIME: {
            type: String,
            default: null,
        },
        arrow: {
            type: Boolean,
            default: true,
            required: false,
        },
        download: {
            type: Boolean,
            default: false,
            required: false,
        },
        to: {
            type: Object,
            default: null,
            required: false,
        },
        network: {
            type: String,
            default: null,
        },
        point: {
            type: String,
            default: null,
        },
        date: {
            type: String,
            default: null,
        },
        inspection: {
            type: Boolean,
            default: false,
        },
        inspectionState: {
            type: String,
            default: '',
        },
        readingType: {
            type: String,
            default: '',
        },
    });

    const goTo = () => {
        if (props.to) {
            $router.push(props.to);
        }
    };

    const colorInspectionSate = computed(() => {
        switch (props.inspectionState) {
            case 'concluído':
            case 'confirmado':
                return 'tw-bg-green';
            case 'negado':
            case 'cancelado':
                return 'tw-bg-red';
            default:
                return 'tw-bg-yellow';
        }
    });

    const textInspectionState = computed(() => {
        switch (props.inspectionState) {
            case 'concluído':
                return 'Concluída';
            case 'confirmado':
                return 'Confirmado';
            case 'negado':
                return 'Negado';
            case 'cancelado':
                return 'Cancelada';
            default:
                return 'Por aprovar';
        }
    });
</script>

<style scoped></style>
