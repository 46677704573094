<template>
    <v-app>
        <Sidebar v-if="isAuthenticated" class="!tw-hidden xl:!tw-block" />
        <v-main :class="{ 'mobile-layout': isMobile }">
            <router-view></router-view>
            <v-alert v-if="errorMessage" closable class="!tw-bottom-4" color="red" density="compact" width="80%"
                max-width="22rem" location="bottom center" position="fixed" :text="errorMessage"></v-alert>
        </v-main>
        <BottomNav class="xl:tw-hidden" v-if="showBottomBar" />
        <PromptUpdate />
    </v-app>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BottomNav from './views/Layout/BottomNav.vue';
import PromptUpdate from './views/PromptUpdate.vue';
import Sidebar from './views/Layout/Sidebar.vue';
import { useAuth } from '@/composables/useAuth';
import _ from 'lodash';
import { ref } from 'vue';
import { useAppStore } from './store/app';
import { useUserStore } from './store/user';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { onMounted } from 'vue';

const appStore = useAppStore();
const userStore = useUserStore();

// check if user is authenticated
// so we can hide/show the sidebar
const isAuthenticated = computed(() => userStore.getToken !== null);

const { xlAndUp } = useDisplay();

const $route = useRoute();
const errorMessage = ref('');

const showBottomBar = computed(() => {
    // check if $route has the meta "hideBottomBar"
    return !$route.meta.hideBottomNav;
});

const isMobile = computed(() => {
    appStore.setIsMobile(!xlAndUp.value);
    return !xlAndUp.value;
});

useAuth().checkAuth();

</script>
<style>
.mobile-layout {
    --v-layout-left: 0px !important;
}
</style>
