<template>
    <div v-if="props.readings.length > 0" class="flex-column justify-start -tw-translate-y-[6rem] tw-p-6 xl:tw-p-0 xl:tw-mt-4">
        <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start gap-2">
            <v-col cols="6" v-if="findReadingValue('ph')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-2 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon color="primary">mdi-ph</v-icon>
                    </div>
                    <p class="tw-text-center tw-text-4xl tw-font-medium">{{ findReadingValue('ph') }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-2xl tw-text-gray_darker">pH</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('desinfetante')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-2 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon color="primary">mdi-cup-water</v-icon><span class="tw-text-xs xs:tw-text-lg lg:tw-text-2xl xl:tw-text-sm tw-inline-block tw-font-medium tw-px-2">mg/l</span>
                    </div>
                    <p class="tw-text-center tw-text-4xl tw-font-medium">{{ findReadingValue('desinfetante') }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-2xl tw-text-gray_darker">Desinfetante</p>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="findReadingValue('temperatura')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-2 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-3 tw-flex tw-items-center">
                        <v-icon color="primary" size="64">mdi-thermometer</v-icon><span class="tw-text-xs xs:tw-text-lg lg:tw-text-2xl xl:tw-text-sm tw-inline-block tw-font-medium tw-px-2">ºC</span>
                    </div>
                    <p class="tw-text-6xl tw-text-center tw-font-medium">{{ findReadingValue('temperatura') }}</p>
                    <p class="tw-text-4xl tw-text-center tw-text-gray_darker tw-leading-tight">Temperatura</p>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="findReadingValue('legionella_pneumophila')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-2 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-3 tw-flex tw-items-center">
                        <v-icon color="primary" size="64">mdi-bacteria-outline</v-icon
                        ><span class="tw-text-xs xs:tw-text-lg lg:tw-text-2xl xl:tw-text-sm tw-inline-block tw-font-medium tw-px-2">ufc/l</span>
                    </div>
                    <p class="tw-text-6xl tw-text-center tw-font-medium">{{ findReadingValue('legionella_pneumophila') }}</p>
                    <p class="tw-text-4xl tw-text-center tw-text-gray_darker tw-leading-tight">Legionella Pneumophila</p>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="findReadingValue('legionella_spp')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-2 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-3 tw-flex tw-items-center">
                        <v-icon color="primary" size="64">mdi-bacteria-outline</v-icon
                        ><span class="tw-text-xs xs:tw-text-lg lg:tw-text-2xl xl:tw-text-sm tw-inline-block tw-font-medium tw-px-2">ufc/l</span>
                    </div>
                    <p class="tw-text-6xl tw-text-center tw-font-medium">{{ findReadingValue('legionella_spp') }}</p>
                    <p class="tw-text-4xl tw-text-center tw-text-gray_darker tw-leading-tight">Legionella spp</p>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';

    const props = defineProps({
        readings: {
            type: Array,
            required: true,
        },
    });

    const fontSizeValueClass = ref('tw-text-2xl');
    const fontSizeTitleClass = ref('tw-text-base');

    const findReadingValue = (value: String) => {
        const reading = props.readings.find((reading) => reading.field == value);
        if (!reading) return null;
        return reading.value;
    };

    onMounted(() => {});
</script>

<style scoped></style>
