<template>
    <!-- Mobile -->
    <div v-if="$route.name == 'Reading'" class="xl:!tw-hidden">
        <TopBar back :notifications="false" :reverse="false" :date="dateTransform" :absolute="false" />
        <div class="tw-relative tw-text-white tw-p-8 tw-px-24 tw-pt-0 tw-mb-24 -tw-translate-y-1 tw-flex tw-flex-col tw-gap-2 tw-bg-primary">
            <!-- <img class="tw-object-bottom tw-w-full tw-max-h-72 tw-object-cover" src="../../assets/svg/header_profile.svg" alt="" /> -->
            <p v-if="control_point.water_network.infrastructure.name" class="tw-text-center tw-font-bold tw-text-base">{{ control_point.water_network.infrastructure.name }}</p>
            <p v-if="control_point.name" class="tw-text-center tw-font-light tw-text-base">{{ control_point.name }}</p>
            <p :class="{ 'tw-bg-green': state, 'tw-bg-yellow': !state }" class="tw-text-center tw-text-white tw-text-sm tw-p-1 tw-rounded-lg">
                {{ state ? 'Análise concluída com sucesso' : 'Problema na análise' }}
            </p>
        </div>
        <Cold_Hot v-if="control_point.water_network.type == 'quente' || control_point.water_network.type == 'fria'" :readings="readings" />
        <Pool v-else-if="control_point.water_network.type == 'piscina'" :readings="readings" />
    </div>
    <!-- Desktop -->
    <div class="!tw-hidden xl:!tw-block">
        <v-navigation-drawer disable-route-watcher width="400" location="right" class="tw-bg-gray_lighter tw-p-4" floating mobile-breakpoint="xl" v-model="modelValue">
            <v-row>
                <v-col cols="12" class="tw-flex tw-flex-end tw-justify-end tw-mb-3">
                    <v-icon @click="modelValue = !modelValue" class="tw-text-primary">mdi-close</v-icon>
                </v-col>
                <!-- <v-col cols="12">
                    <img v-if="!infrastructurePicture" class="tw-rounded-full tw-aspect-square tw-object-cover tw-w-36 tw-mx-auto" src="@/assets/icons/calendar_inspections.svg" alt="" />
                    <img v-else class="tw-rounded-full tw-aspect-square tw-object-cover tw-w-36 tw-mx-auto" :src="infrastructurePicture" alt="" />
                </v-col> -->
                <v-col cols="12">
                    <h1 v-if="control_point.water_network.infrastructure.name" class="tw-text-2xl text-black tw-font-bold tw-text-center tw-mb-4">
                        {{ control_point.water_network.infrastructure.name }}
                    </h1>
                    <p v-if="control_point.name" class="tw-text-center tw-font-light tw-text-base tw-mb-4">{{ control_point.name }}</p>
                    <p :class="{ 'tw-bg-green': state, 'tw-bg-yellow': !state }" class="tw-text-center tw-text-white tw-text-sm tw-p-1 tw-rounded-lg tw-w-1/2 tw-mx-auto">
                        {{ state ? 'Análise concluída com sucesso' : 'Problema na análise' }}
                    </p>
                </v-col>
                <v-col cols="12" class="tw-mt-20">
                    <Cold_Hot v-if="control_point.water_network.type == 'quente' || control_point.water_network.type == 'fria'" :readings="readings" />
                    <Pool v-else-if="control_point.water_network.type == 'piscina'" :readings="readings" />
                </v-col>
            </v-row>
        </v-navigation-drawer>
    </div>
</template>

<script setup lang="ts">
    import TopBar from '@/views/Layout/TopBar.vue';
    import Cold_Hot from './Reading/Cold_Hot.vue';
    import Pool from './Reading/Pool.vue';
    import { ref, onMounted, computed, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useAppStore } from '../../store/app';
    import axios from '@/plugins/axios';
    import qs from 'qs';

    const modelValue = defineModel({
        default: false,
    });

    const appStore = useAppStore();

    const $route = useRoute();
    const idReadingSet = ref();
    const data = ref({ date: new Date().toISOString().substr(0, 10) });
    const readings = ref([]);
    const control_point = ref({ water_network: { infrastructure: {} } });
    const state = ref();

    const props = defineProps(['readingId']);

    const query = ref({
        populate: ['readings', 'control_point.water_network.infrastructure'],
    });

    const getReading = async () => {
        console.log('getReading');
        const readingId = isMobile.value ? idReadingSet.value : props.readingId;
        const res = await axios.get(`/reading-sets/${readingId}?${qs.stringify(query.value)}`);
        data.value = res.data.data;

        readings.value = data.value.readings;
        state.value = readings.value.every((reading) => reading.status == 'conforme' || reading.status == '' || reading.status == null || reading.status == 'risco baixo');
        control_point.value = data.value.control_point;
    };

    const isMobile = computed(() => appStore.isMobile);

    const dateTransform = computed(() => {
        if (!data.value.date) {
            return new Date().toISOString().substr(0, 10).split('-').reverse().join('/');
        } else {
            return data.value.date.split('T')[0].split('-').reverse().join('/');
        }
    });

    watch(
        () => props.readingId,
        async () => {
            if (props.readingId) {
                idReadingSet.value = props.readingId;
                await getReading();
            }
        },
    );

    onMounted(async () => {
        idReadingSet.value = $route.params.id;
        await getReading();
    });
</script>

<style scoped></style>
