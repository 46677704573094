<template>
    <v-form ref="form" class="tw-flex tw-flex-col tw-gap-3" @submit.prevent="createReading">
        <v-row>
            <v-col v-if="readingtype == 'diário' || readingtype == 'físico-química'" cols="12">
                <v-text-field
                    v-model="json[1].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v, 15)]"
                    label="pH"
                    persistent-hint
                    hint="entre 7,4 e 7,8"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'diário' || readingtype == 'físico-química'" cols="12">
                <v-text-field
                    v-model="json[2].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Cloro Livre"
                    persistent-hint
                    hint="entre 0,5 e 1,2 se pH entre 7 e 7,4; entre 1 e 2 se pH entre 7,4 e 7,8"
                    variant="outlined"></v-text-field
            ></v-col>
            <v-col v-if="readingtype == 'diário' || readingtype == 'físico-química'" cols="12">
                <v-text-field
                    v-model="json[3].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Cloro Combinado"
                    persistent-hint
                    hint="≤ 0,6"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'diário' || readingtype == 'físico-química'" cols="12">
                <v-text-field
                    v-model="json[0].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v, -100), (v) => rules.maxNumber(v)]"
                    label="Temperatura"
                    persistent-hint
                    hint="entre 24ºC e 30ºC"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'físico-química'" cols="12">
                <v-text-field
                    v-model="json[4].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v, 0), (v) => rules.maxNumber(v, 100000)]"
                    label="Condutividade"
                    persistent-hint
                    hint="menor que 1700"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'físico-química'" cols="12">
                <v-text-field
                    v-model="json[5].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Oxidabilidade"
                    persistent-hint
                    hint="≤ 4"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'físico-química'" cols="12">
                <v-text-field
                    v-model="json[6].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Amoníaco"
                    persistent-hint
                    hint="≤ 0,5"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-extensa'" cols="12">
                <v-text-field
                    v-model="json[7].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Coliformes Totais"
                    persistent-hint
                    hint="entre 0 e 10"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-extensa'" cols="12">
                <v-text-field
                    v-model="json[8].value"
                    type="number"
                    hide-details="auto"
                    persistent-hint
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Eacherichia Coli"
                    hint="0"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-extensa'" cols="12">
                <v-text-field
                    v-model="json[9].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Enterecocos Fecais"
                    persistent-hint
                    hint="0"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-extensa'" cols="12">
                <v-text-field
                    v-model="json[10].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Pseudomonas Aeruginosas"
                    persistent-hint
                    hint="0"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-extensa'" cols="12">
                <v-text-field
                    v-model="json[11].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Staphylococcus"
                    persistent-hint
                    hint="≤ 20"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-extensa'" cols="12">
                <v-text-field
                    v-model="json[12].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Staphylococcus Produtores de Coagulase"
                    persistent-hint
                    hint="0 em 90% dos casos"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-extensa'" cols="12">
                <v-text-field
                    v-model="json[13].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v, 1000000)]"
                    label="Microrganismos viáveis a 37°C durante 24h"
                    persistent-hint
                    hint="entre 0 e 100"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-legionella'" cols="12">
                <v-text-field
                    class="italic"
                    v-model="json[14].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v, 0), (v) => rules.maxNumber(v, 100000)]"
                    label="Legionella Pneumophila"
                    persistent-hint
                    hint="= 0"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-legionella'" cols="12">
                <v-text-field
                    v-model="json[15].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v, 0), (v) => rules.maxNumber(v, 100000)]"
                    label="Legionella spp"
                    persistent-hint
                    hint="< 100"
                    variant="outlined"></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters class="fill-width fill-height justify-center align-content-end mt-4">
            <v-col cols="12" v-if="errorMessage">
                <p class="tw-text-red tw-text-center tw-mb-4" v-html="errorMessage"></p>
            </v-col>
            <v-col cols="6">
                <v-btn :loading="loading" flat type="submit" size="large" block rounded="lg" class="bg-primary"> Concluir</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import rules from '@/composables/rules';
    import axios from '@/plugins/axios';
    import { useRouter } from 'vue-router';
    import _ from 'lodash';
    import { useAppStore } from '@/store/app';

    const props = defineProps({
        idReadingSet: {
            required: false,
        },
        readingtype: {
            required: false,
        },
    });

    const appStore = useAppStore();

    const $router = useRouter();
    const loading = ref(false);
    const form = ref<HTMLFormElement>();
    const errorMessage = ref('');

    const json = ref([
        {
            field: 'temperatura',
            value: null,
        },
        {
            field: 'ph',
            value: null,
        },
        {
            field: 'cloro_livre',
            value: null,
        },
        {
            field: 'cloro_combinado',
            value: null,
        },
        {
            field: 'condutividade',
            value: null,
        },
        {
            field: 'oxidabilidade',
            value: null,
        },
        {
            field: 'amoniaco',
            value: null,
        },
        {
            field: 'coliformes_totais',
            value: null,
        },
        {
            field: 'eachechia_coli',
            value: null,
        },
        {
            field: 'enterecocos_fecais',
            value: null,
        },
        {
            field: 'pseudomonas_aeruginosa',
            value: null,
        },
        {
            field: 'staphylococcus',
            value: null,
        },
        {
            field: 'staphylococcus_produtor_de_coagulase',
            value: null,
        },
        {
            field: 'microrganismos_viaveis',
            value: null,
        },
        {
            field: 'legionella_pneumophila',
            value: null,
        },
        {
            field: 'legionella_spp',
            value: null,
        },
    ]);

    const isMobile = computed(() => appStore.isMobile);

    const createReading = async () => {
        if (!form.value) return;

        // reset validation
        form.value?.resetValidation();

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid, errors } = await form.value?.validate();

        if (!valid) return;

        loading.value = true;

        let readings = json.value.filter((reading: any) => reading.value !== null);

        try {
            const res = await axios.post('/readings/many', { data: readings });
            const data = res.data.data;

            let readingIds = data.map((reading: any) => reading.id);

            await axios.put(`/reading-sets/${props.idReadingSet}`, { data: { readings: readingIds } });
        } catch (error: any) {
            let message = _.get(error, 'response.data.error.message', _.get(error, 'message', ''));
            errorMessage.value = `Ocorreu um erro: <br> ${message}`;
        } finally {
            if (isMobile.value) $router.push('/readings');
            if (!isMobile.value) $router.go(0);
            loading.value = false;
        }
    };
</script>

<style scoped></style>
