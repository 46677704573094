import { useUserStore } from '@/store/user';
import { Socket, io } from 'socket.io-client';

let socket: Socket | null = null;

export function useSocket() {
    const userStore = useUserStore();

    if (!socket)
        socket = io(import.meta.env.VITE_API_URL, {
            closeOnBeforeunload: true,
            withCredentials: true,
            auth: {
                token: userStore.getToken,
            },
        });
    return socket;
}