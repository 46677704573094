<template>
    <TopBar :title="title" :absolute="false" :reverse="reverse" :back="back" :notifications="notifications" :create="create" :edit="edit" />
    <div v-if="title" class="tw-pb-16 -tw-mb-16 tw-bg-primary -tw-translate-y-0.5">
        <v-container class="!tw-pt-1 !tw-pb-0 tw-text-white tw-flex tw-justify-end tw-items-center">
            <router-link :to="{ name: 'Schedule' }" v-if="route == 'Inspections'">
                <div class="tw-w-fit tw-border-white tw-border-[1px] tw-rounded-full tw-px-3 tw-py-2 tw-items-center tw-flex"><span class="tw-text-xs">Agendar +</span></div>
            </router-link>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import TopBar from '../Layout/TopBar.vue';
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';

    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
        reverse: {
            type: Boolean,
            default: true,
        },
        back: {
            type: Boolean,
            default: false,
        },
        notifications: {
            type: Boolean,
            default: true,
        },
        absolute: {
            type: Boolean,
            default: true,
        },
        create: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
    });

    const $route = useRoute();

    const route = computed(() => {
        return $route.name;
    });
</script>

<style scoped></style>
