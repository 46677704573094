<template>
    <v-form ref="form" class="tw-flex tw-flex-col tw-gap-3" @submit.prevent="createReading">
        <v-row>
            <v-col v-if="true" cols="12">
                <v-text-field
                    v-model="json[1].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v, 15)]"
                    label="pH"
                    persistent-hint
                    hint="entre 6,5 e 9,5"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="true" cols="12">
                <v-text-field
                    v-model="json[2].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
                    label="Desinfetante"
                    persistent-hint
                    hint="entre 0,5 e 1"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="true" cols="12">
                <v-text-field
                    hide-details="auto"
                    v-model="json[0].value"
                    type="number"
                    :rules="[rules.required, (v) => rules.minNumber(v, -100), (v) => rules.maxNumber(v)]"
                    label="Temperatura"
                    persistent-hint
                    hint="> 50ºC"
                    variant="outlined"></v-text-field>
            </v-col>
            <!-- @vue-ignore -->
            <v-col cols="12" v-if="(json[2].value > 1 || (json[2].value < 0.5 && json[2].value != null && json[2].value != '')) && true && readingtype == 'microbiológica-legionella'">
                <v-text-field
                    v-model="json[3].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v, -100), (v) => rules.maxNumber(v)]"
                    label="Colonias"
                    persistent-hint
                    hint="> 1"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col cols="12" v-if="readingtype == 'microbiológica-legionella'">
                <v-text-field
                    class="italic"
                    v-model="json[4].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v, 0), (v) => rules.maxNumber(v, 100000)]"
                    label="Legionella Pneumophila"
                    persistent-hint
                    hint="= 0"
                    variant="outlined"></v-text-field>
            </v-col>
            <v-col v-if="readingtype == 'microbiológica-legionella'" cols="12">
                <v-text-field
                    v-model="json[5].value"
                    type="number"
                    hide-details="auto"
                    :rules="[rules.required, (v) => rules.minNumber(v, 0), (v) => rules.maxNumber(v, 100000)]"
                    label="Legionella spp"
                    persistent-hint
                    hint="< 100"
                    variant="outlined"></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters class="fill-width fill-height justify-center align-content-end mt-4">
            <v-col cols="12" v-if="errorMessage">
                <p class="tw-text-red tw-text-center tw-mb-4" v-html="errorMessage"></p>
            </v-col>
            <v-col cols="6">
                <v-btn :loading="loading" flat type="submit" size="large" block rounded="lg" class="bg-primary"> Concluir</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import rules from '@/composables/rules';
    import axios from '@/plugins/axios';
    import { useRouter } from 'vue-router';
    import { useAppStore } from '@/store/app';
    import _ from 'lodash';

    const props = defineProps({
        idReadingSet: {
            required: false,
        },
        readingtype: {
            required: false,
        },
    });

    const appStore = useAppStore();

    const $router = useRouter();
    const loading = ref(false);
    const form = ref<HTMLFormElement>();
    const errorMessage = ref('');

    const json = ref([
        {
            field: 'temperatura',
            value: null,
        },
        {
            field: 'ph',
            value: null,
        },
        {
            field: 'desinfetante',
            value: null,
        },
        {
            field: 'colonias',
            value: null,
        },
        {
            field: 'legionella_pneumophila',
            value: null,
        },
        {
            field: 'legionella_spp',
            value: null,
        },
    ]);

    const isMobile = computed(() => appStore.isMobile);

    const createReading = async () => {
        if (!form.value) return;

        // reset validation
        form.value?.resetValidation();
        errorMessage.value = '';

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid } = await form.value?.validate();

        if (!valid) return;

        loading.value = true;

        let readings = json.value.filter((reading: any) => reading.value !== null);

        try {
            const res = await axios.post('/readings/many', { data: readings });
            const data = res.data.data;

            let readingIds = data.map((reading: any) => reading.id);
            await axios.put(`/reading-sets/${props.idReadingSet}`, { data: { readings: readingIds } });
        } catch (error: any) {
            let message = _.get(error, 'response.data.error.message', _.get(error, 'message', ''));
            errorMessage.value = `Ocorreu um erro: <br> ${message}`;
        } finally {
            if (isMobile.value) $router.push('/readings');
            if (!isMobile.value) $router.go(0);
            loading.value = false;
        }
    };
</script>

<style scoped></style>
