import _ from 'lodash';

// load all extension in an array
let exts = ["aac", "css", "eps", "iso", "mp4", "pdf", "rb", "txt", "aiff", "csv", "exe", "java", "mpg", "php", "rtf", "wav", "ai", " dat", "flv", "jpg", "odf", "png", "sass", "xls", "avi", "dmg", "gif", "js", "ods", "ppt", "scss", "xlsx", "blank", "doc", "h", " key", "odt", "psd", "sql", "xml", "bmp", "dotx", "hpp", "less", "otp", "py", "tga", "yml", "c", "dwg", "html", "mid", "ots", "qt", "tgz", "zip", "cpp", "dxf", "ics", "mp3", "ott", "rar", "tiff"]

export default function useMedia(mime: any) {
    // split the mime type into type and subtype
    let parts = mime.split('.');

    // take the last part
    let subtype = _.last(parts);

    if (_.includes(exts, subtype)) {
        return `/mimes/${subtype}.png`;
    } else {
        return `/mimes/blank.png`;
    }

}
