// Composables
import { createRouter, createWebHistory } from 'vue-router';

import Login from '@/views/Login.vue';
import CompleteData from '@/views/CompleteData.vue';
import Profile from '@/views/Profile.vue';
import Technician from '@/views/Technician.vue';
import CreateTechnician from '@/views/CreateTechnician.vue';
import CreateNetwork from '@/views/CreateNetwork.vue';
import Infrastructures from '@/views/Infrastructures/List.vue';
import Infrastructure from '@/views/Infrastructures/Page.vue';
import Network from '@/views/Infrastructures/Network.vue';
import Readings from '@/views/Readings/List.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import CreateReading from '@/views/Readings/Create.vue';
import Reading from '@/views/Readings/Reading.vue';
import Inspections from '@/views/Inspections/List.vue';
import Inspection from '@/views/Inspections/Inspection.vue';
import Schedule from '@/views/Inspections/Schedule.vue';
import Notifications from '@/views/Notifications.vue';
import Analysis from '@/views/Analysis.vue';
import Documents from '@/views/Documents.vue';
//import component from '../vite-env';
import { useUserStore } from '@/store/user';
import { useAppStore } from '@/store/app';

const routes = [
    {
        path: '/',
        component: Login,
        name: 'Login',
        meta: {
            hideBottomNav: true,
            auth: false,
        },
    },
    {
        path: '/complete-data',
        component: CompleteData,
        name: 'CompleteData',
        meta: {
            hideBottomNav: true,
            auth: false,
        },
    },
    {
        path: '/profile',
        component: Profile,
        name: 'Profile',
        meta: {
            hideBottomNav: false,
            auth: true,
        },
    },
    {
        path: '/technician/:id',
        component: Technician,
        name: 'Technician',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/create-technician',
        component: CreateTechnician,
        name: 'CreateTechnician',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/edit-technician/:id',
        component: CreateTechnician,
        name: 'EditTechnician',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/create-network',
        component: CreateNetwork,
        name: 'CreateNetwork',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/infrastructures',
        component: Infrastructures,
        name: 'Infrastructures',
        meta: {
            hideBottomNav: false,
            auth: true,
        },
    },
    {
        path: '/infrastructure/:infrastucture_id',
        component: Infrastructure,
        name: 'Infrastructure',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/network/:infrastucture_id/:water_network_id',
        component: Network,
        name: 'Network',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/readings',
        component: Readings,
        name: 'Readings',
        meta: {
            hideBottomNav: false,
            auth: true,
        },
    },
    {
        path: '/create-reading/:id',
        component: CreateReading,
        name: 'CreateReading',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/reading/:id',
        component: Reading,
        name: 'Reading',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/inspections',
        component: Inspections,
        name: 'Inspections',
        meta: {
            hideBottomNav: false,
            auth: true,
        },
    },
    {
        path: '/inspection/:idInspection',
        component: Inspection,
        name: 'Inspection',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/schedule',
        component: Schedule,
        name: 'Schedule',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/analysis',
        component: Analysis,
        name: 'Analysis',
        meta: {
            hideBottomNav: false,
            auth: true,
        },
    },
    {
        path: '/notifications',
        component: Notifications,
        name: 'Notifications',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'ForgotPassword',
        meta: {
            hideBottomNav: true,
            auth: false,
        },
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        name: 'ResetPassword',
        meta: {
            hideBottomNav: true,
            auth: false,
        },
    },
    {
        path: '/documents',
        component: Documents,
        name: 'Documents',
        meta: {
            hideBottomNav: true,
            auth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    useAppStore().setEditing(false);

    if (to.meta.auth && useUserStore().getToken) {
        next();
    } else if (to.meta.auth && !useUserStore().getToken) {
        next({ name: 'Login' });
    } else if (!to.meta.auth && useUserStore().getToken) {
        next({ name: 'Infrastructures' });
    } else if (!to.meta.auth) {
        next();
    }
});

export default router;
