<template>
    <div class="xl:!tw-hidden">
        <Top title="Análises" :notifications="!userStore.isTechnician" />
    </div>
    <v-container class="flex-column justify-start tw-flex tw-gap-4 tw-h-full !tw-pb-[var(--bottomBarHeight)] xl:!tw-pt-9 xl:!tw-px-16">
        <h1 class="!tw-hidden xl:!tw-block tw-mr-auto tw-text-2xl tw-font-bold">Análises</h1>
        <v-row class="fill-width h-auto justify-start align-content-start !tw-flex-none">
            <v-col cols="6" xl="3">
                <v-btn
                    @click="changeView('graphic')"
                    block
                    variant="tonal"
                    size="large"
                    rounded="xl"
                    :class="activeComponentComp == 'graphic' ? 'bg-primary_light' : 'bg-gray_light'"
                    class="tw-mt-4 text-none"
                    >Gráfico</v-btn
                >
            </v-col>
            <v-col cols="6" xl="3">
                <v-btn
                    @click="changeView('excel')"
                    block
                    variant="tonal"
                    size="large"
                    rounded="xl"
                    :class="activeComponentComp == 'excel' ? 'bg-primary_light' : 'bg-gray_light'"
                    class="tw-mt-4 text-none bg-primary_light"
                    >Ficheiro Excel</v-btn
                >
            </v-col>
        </v-row>
        <div class="tw-flex tw-flex-col tw-gap-4 xl:!tw-grid xl:!tw-grid-cols-2" v-if="activeComponent == 'graphic'">
            <v-select
                :rules="[]"
                hide-details="auto"
                variant="outlined"
                placeholder="Escolha uma infraestrutura"
                item-value="id"
                item-title="name"
                clearable
                v-model="graphicFilters.infrastructure"
                :items="infrastructures"></v-select>
            <v-select
                :rules="[]"
                hide-details="auto"
                variant="outlined"
                placeholder="Escolha uma rede"
                item-value="id"
                item-title="name"
                clearable
                :disabled="!graphicFilters.infrastructure"
                v-model="graphicFilters.network"
                :items="waterNetworksGraphic"></v-select>
            <v-select
                :rules="[]"
                hide-details="auto"
                variant="outlined"
                placeholder="Escolha um ponto"
                @update:model-value="onControlPointChanged"
                item-value="id"
                item-title="name"
                clearable
                :disabled="!graphicFilters.infrastructure || !graphicFilters.network"
                v-model="graphicFilters.point"
                :items="pointsGraphic"></v-select>
            <v-select
                :rules="[]"
                hide-details="auto"
                variant="outlined"
                placeholder="Escolha os parametros"
                item-value="type"
                item-title="name"
                multiple
                :loading="isParamsLoading"
                clearable
                @update:model-value="onParamsChange"
                :disabled="!graphicFilters.infrastructure || !graphicFilters.network || !graphicFilters.point"
                v-model="graphicFilters.params"
                :items="fields">
            </v-select>
            <v-row class="xl:!tw-col-span-2" v-if="isChartActive">
                <v-col offset="8" offset-xl="0" class="tw-flex tw-flex-col tw-flex-wrap tw-content-end">
                    <v-btn @click="onClear" class="xl:!tw-hidden tw-w-1/2 tw-m-auto" size="large" flat color="primary">Limpar</v-btn>
                    <v-btn @click="onClear" class="!tw-hidden xl:!tw-block xl:tw-col-span-2 tw-w-1/2 xl:tw-w-1/4 xl:!tw-ml-auto tw-m-auto" size="large" flat color="primary"> Limpar </v-btn>
                </v-col>
            </v-row>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-4 xl:!tw-grid xl:!tw-grid-cols-2" v-if="activeComponent == 'excel'">
            <v-select class="xl:tw-col-span-2" :rules="[]" hide-details="auto" variant="outlined" item-title="title" item-value="type" v-model="excelFilters.state" :items="excelStates"></v-select>
            <v-select
                :rules="[]"
                hide-details="auto"
                clearable
                :readonly="excelFilters.state == 'default'"
                variant="outlined"
                placeholder="Escolha uma infraestrutura"
                item-value="id"
                item-title="name"
                multiple
                :disabled="excelFilters.state == 'default'"
                v-model="excelFilters.infrastructures"
                :items="infrastructures"></v-select>
            <v-select
                :rules="[]"
                hide-details="auto"
                clearable
                :readonly="excelFilters.infrastructures.length == 0"
                variant="outlined"
                placeholder="Escolha uma rede"
                item-value="id"
                item-title="name"
                multiple
                :disabled="excelFilters.state == 'default' || excelFilters.infrastructures.length == 0"
                v-model="excelFilters.water_networks"
                :items="waterNetworksExcel"></v-select>
            <v-select
                :rules="[]"
                hide-details="auto"
                clearable
                :readonly="excelFilters.water_networks.length == 0"
                variant="outlined"
                placeholder="Escolha o/os pontos"
                item-value="id"
                item-title="name"
                multiple
                :disabled="excelFilters.state == 'default' || excelFilters.infrastructures.length == 0 || excelFilters.water_networks.length == 0"
                v-model="excelFilters.control_points"
                :items="pointsExcel"></v-select>
            <v-select
                :rules="[]"
                hide-details="auto"
                clearable
                :readonly="excelFilters.control_points.length == 0"
                variant="outlined"
                placeholder="Todos os parâmetros"
                multiple
                item-value="type"
                item-title="name"
                :disabled="excelFilters.state == 'default' || excelFilters.infrastructures.length == 0 || excelFilters.water_networks.length == 0 || excelFilters.control_points.length == 0"
                v-model="excelFilters.parameters"
                :items="params"></v-select>
            <v-btn @click="download" class="xl:tw-col-span-2 tw-w-1/2 xl:tw-w-1/4 xl:!tw-ml-auto xl:!tw-m-0 !tw-m-auto" size="large" flat color="primary"> Download </v-btn>
        </div>
        <div id="chart" :class="{ 'tw-h-96': isChartActive }" class="tw-flex tw-justify-center"></div>
        <div v-show="noData">
            <h4 class="tw-text-center tw-text-red">Não existem dados a mostrar com os filtros selecionados!</h4>
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import * as echarts from 'echarts';
    import Top from './Layout/Top.vue';
    import { ref, computed, onMounted, watch } from 'vue';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import { useUserStore } from '@/store/user';
    import { toRaw } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { nextTick } from 'vue';
    import moment from 'moment';

    const activeComponent = ref('graphic');
    const userStore = useUserStore();
    const $router = useRouter();
    const $route = useRoute();
    const isChartActive = ref(false);
    const noData = ref(false);
    const controlPointFields = ref([]);
    const isParamsLoading = ref(false);

    const graphicLines = ref({
        ph: true,
        desinfetante: true,
        temperatura: true,
    });

    const chart = ref();

    const infrastructures = ref([]);

    const params = ref([
        {
            name: 'pH',
            type: 'ph',
        },
        {
            name: 'Desinfetante (mg/l)',
            type: 'desinfetante',
        },
        {
            name: 'Temperatura (ºC)',
            type: 'temperatura',
        },
        {
            name: 'Cloro livre (mg/l de Cl2)',
            type: 'cloro_livre',
        },
        {
            name: 'Cloro total (mg/l de Cl2)',
            type: 'cloro_total',
        },
        {
            name: 'Cloro combinado (mg/l de Cl2)',
            type: 'cloro_combinado',
        },
        {
            name: 'Condutividade (μS/cm)',
            type: 'condutividade',
        },
        {
            name: 'Oxidabilidade (mg/l de O2)',
            type: 'oxidabilidade',
        },
        {
            name: 'Amoniaco (mg/l)',
            type: 'amoniaco',
        },
        {
            name: 'Coliformes totais (ufc/100ml)',
            type: 'coliformes_totais',
        },
        {
            name: 'Eachechia coli (ufc/100ml)',
            type: 'eachechia_coli',
        },
        {
            name: 'Enterococos fecias (ufc/100ml)',
            type: 'enterococos_fecais',
        },
        {
            name: 'Pseudomonas aeruginosa (ufc/100ml)',
            type: 'pseudomonas_aeruginosa',
        },
        {
            name: 'Staphylococcus (ufc/100ml)',
            type: 'staphylococcus',
        },
        {
            name: 'Staphylococcus produtor de coagulase (ufc/100ml)',
            type: 'staphylococcus_produtor_de_coagulase',
        },
        {
            name: 'Microroganismos viaveis (ufc/100ml)',
            type: 'microrganismos_viaveis',
        },
    ]);

    const excelStates = [
        { type: 'default', title: 'Tudo' },
        { type: 'custom', title: 'Personalizado' },
    ];

    const excelFilters = ref({
        state: 'default',
        infrastructures: [],
        water_networks: [],
        control_points: [],
        parameters: [],
    });

    const graphicFilters = ref({
        infrastructure: null,
        network: null,
        point: null,
        params: [],
    });

    const fields = computed(() => {
        // return the from the params that are also in the controlPointFields~
        return params.value.filter((param) => controlPointFields.value.includes(param.type));
    });

    const waterNetworksGraphic = computed(() => {
        return infrastructures.value.find((infrastructure) => infrastructure.id == graphicFilters.value.infrastructure)?.water_networks;
    });

    const waterNetworksExcel = computed(() => {
        let networks = [];

        infrastructures.value.forEach((infrastructure) => {
            if (excelFilters.value.infrastructures.includes(infrastructure.id)) {
                infrastructure.water_networks.forEach((network) => {
                    networks.push(network);
                });
            }
        });

        return networks;

        // return infrastructures.value.find((infrastructure) => infrastructure.id == excelFilters.value.infrastructure)?.water_networks;
    });

    const pointsGraphic = computed(() => {
        return waterNetworksGraphic.value ? waterNetworksGraphic.value.find((network) => network.id == graphicFilters.value.network)?.control_points : [];
    });

    const pointsExcel = computed(() => {
        let points = [];

        waterNetworksExcel.value.forEach((network) => {
            if (excelFilters.value.water_networks.includes(network.id)) {
                network.control_points.forEach((point) => {
                    points.push(point);
                });
            }
        });

        return points;

        // return waterNetworksExcel.value ? waterNetworksExcel.value.find((network) => network.id == excelFilters.value.network)?.control_points : [];
    });

    const changeView = (view: string) => {
        activeComponent.value = view;
        //reset everything from previous view
        onClear();
    };

    const getInfrastructures = async () => {
        const query = {
            populate: ['water_networks.control_points'],
            sort: 'name',
            filters: {
                ...(userStore.isTechnician
                    ? {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      }
                    : {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client.id,
                              },
                          },
                      }),
            },
            pagination: {
                page: 1,
                pageSize: 1000,
            },
        };
        try {
            const res = await axios.get(`/infrastructures?${qs.stringify(query)}`);
            infrastructures.value = res.data.data;
        } catch (error) {
            console.log(error);
        }
    };

    const activeComponentComp = computed(() => {
        return activeComponent.value;
    });

    const download = async () => {
        if (excelFilters.value.state !== 'default') {
            let filters = {
                infrastructures: excelFilters.value.infrastructures,
                water_networks: excelFilters.value.water_networks,
                control_points: excelFilters.value.control_points,
                parameters: excelFilters.value.parameters,
            };

            filters.infrastructures = JSON.stringify(filters.infrastructures);
            filters.water_networks = JSON.stringify(filters.water_networks);
            filters.control_points = JSON.stringify(filters.control_points);
            filters.parameters = JSON.stringify(filters.parameters);

            const res = await axios.get(`/export?${qs.stringify(filters)}`);
            // console.log(res.data);
            // download the file from res.data.data as a base64 and with file name res.data.name
            window.open(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.data}`);

            // window.open(`${import.meta.env.VITE_API_URL}/uploads/${res.data.name}`);
        } else {
            let filters = {
                infrastructures: [],
                water_networks: [],
                control_points: [],
                parameters: [],
            };

            infrastructures.value.forEach((infrastructure) => {
                filters.infrastructures.push(infrastructure.id);
                infrastructure.water_networks.forEach((network) => {
                    filters.water_networks.push(network.id);
                    network.control_points.forEach((point) => {
                        filters.control_points.push(point.id);
                    });
                });
            });

            params.value.forEach((param) => {
                filters.parameters.push(param.type);
            });

            filters.infrastructures = JSON.stringify(filters.infrastructures);
            filters.water_networks = JSON.stringify(filters.water_networks);
            filters.control_points = JSON.stringify(filters.control_points);
            filters.parameters = JSON.stringify(filters.parameters);

            const res = await axios.get(`/export?${qs.stringify(excelFilters.value)}`);
            // console.log(res.data);
            // window.open(`${import.meta.env.VITE_API_URL}/uploads/${res.data.name}`);
            
            // download the file from res.data.data as a base64 and with file name res.data.name
            window.open(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.data}`);
        }
        onClear();
    };

    async function onControlPointChanged() {
        isParamsLoading.value = true;
        await nextTick();
        controlPointFields.value = [];

        if (!graphicFilters.value.point) return;

        const query = {
            populate: ['reading_set', 'reading_set.control_point'],
            filters: {
                reading_set: {
                    control_point: {
                        id: {
                            $eq: graphicFilters.value.point,
                        },
                    },
                },
            },
            pagination: {
                pageSize: 10000,
            },
        };

        const {
            data: { data },
        } = await axios.get(`/readings?${qs.stringify(query)}`);

        data.forEach((reading: any) => {
            if (!controlPointFields.value.includes(reading.field)) {
                controlPointFields.value.push(reading.field);
            }
        });
        isParamsLoading.value = false;
    }

    async function onParamsChange() {
        await nextTick();

        if (!graphicFilters.value.point || !graphicFilters.value.params || !graphicFilters.value.params.length) return;

        isChartActive.value = true;
        noData.value = false;

        const query = {
            filters: {
                reading_set: {
                    control_point: {
                        id: {
                            $eq: graphicFilters.value.point,
                        },
                    },
                },
                field: {
                    $in: graphicFilters.value.params,
                },
            },
            populate: {
                reading_set: {
                    fields: ['date'],
                    control_point: true,
                },
            },
            pagination: {
                pageSize: 10000,
            },
        };

        let {
            data: { data },
        } = await axios.get(`/readings?${qs.stringify(query)}`);

        if (!data.length) {
            noData.value = true;
            isChartActive.value = false;
            toRaw(chart.value).dispose();
            return;
        }

        console.log(
            data
                .map((item: any) => item.field)
                .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                .map((fieldType: string) => params.value.find((param) => param.type == fieldType)?.name),
        );

        mountChart();

        data = data.sort((a: any, b: any) => new Date(a.reading_set.date).getTime() - new Date(b.reading_set.date).getTime());
        toRaw(chart.value).clear();
        toRaw(chart.value).setOption({
            tooltip: {
                //trigger: 'axis',
                //axisPointer: { type: 'cross' },
            },
            legend: {
                // get all distinct field from data
                data: data.map((item: any) => item.field).filter((value: any, index: any, self: any) => self.indexOf(value) === index),
                formatter: (name: string) => {
                    return params.value.find((param) => param.type == name)?.name;
                },
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                // xAxis is the date inside all the reading_sets, that are inside each reading
                data: data
                    .map((item: any) => item.reading_set.date)
                    .map((date: any) => moment(date).format('DD/MM/YYYY'))
                    .filter((value: any, index: any, self: any) => self.indexOf(value) === index),
            },
            yAxis: {
                type: 'value',
            },
            series: data.map((item: any) => ({
                name: item.field,
                type: 'line',
                data: data.filter((reading: any) => reading.field == item.field).map((reading: any) => reading.value),
            })),
        });
        const chartEl = document.getElementById('chart');
        toRaw(chart.value).resize({
            width: chartEl?.offsetWidth,
            height: chartEl?.offsetHeight,
        });
    }

    function mountChart() {
        chart.value = echarts.init(document.getElementById('chart') as HTMLDivElement);
    }

    const onClear = () => {
        isChartActive.value = false;
        noData.value = false;
        graphicFilters.value = {
            infrastructure: null,
            network: null,
            point: null,
            params: [],
        };
        toRaw(chart.value).dispose();
        excelFilters.value = {
            state: 'default',
            infrastructures: [],
            water_networks: [],
            control_points: [],
            parameters: [],
        };
    };

    onMounted(() => {
        getInfrastructures();
        mountChart();
    });
</script>

<style scoped></style>
