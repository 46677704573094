<template>
    <!-- Mobile -->
    <div v-if="$route.name == 'Technician'" class="xl:!tw-hidden">
        <Top :back="true" :edit="true" :notifications="false" />
        <div class="tw-relative tw-mb-20 sm:tw-mb-14 -tw-translate-y-0.5">
            <img class="tw-object-bottom tw-object-cover" src="../assets/svg/header_profile.png" alt="" />
            <img
                class="tw-rounded-full tw-aspect-square tw-object-cover tw-absolute tw-w-28 tw-bottom-0 tw-left-1/2 tw-translate-y-[71%] xs:tw-translate-y-[65%] sm:tw-translate-y-[44%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
                :src="useMedia(tech.picture, 'user').large"
                alt="" />
        </div>
        <v-container class="flex-column justify-start tw-relative tw-flex tw-flex-1 !tw-pt-2" fluid>
            <v-row align="start" class="fill-width justify-start tw-bg-white !tw-flex-shrink-0 !tw-flex-grow-0" no-gutters>
                <v-col cols="12">
                    <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ tech.name }}</h1>
                </v-col>
                <v-col cols="12">
                    <h1 class="text-black tw-font-bold tw-text-center">{{ tech.email }}</h1>
                </v-col>
            </v-row>
            <span class="tw-my-4 tw-font-medium tw-text-lg">Infraestruturas associadas</span>
            <div class="tw-relative tw-mb-8">
                <div class="tw-flex tw-flex-col tw-items-center tw-gap-4">
                    <Card v-for="(infra, i) in infrastructures" :key="i" :title="infra.name" :image="useMedia(infra.picture, 'infrastructure').small" :arrow="false" />
                </div>
            </div>

            <v-dialog width="500">
                <template v-slot:activator="{ props }">
                    <v-btn :loading="isLoading" v-bind="props" block rounded="lg" color="red">Eliminar técnico</v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                    <v-card title="Eliminar técnico">
                        <v-card-text>
                            <span class="tw-text-lg">Tem a certeza que pretende eliminar este técnico?</span>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text="Sim"
                                color="red"
                                @click="
                                    isActive.value = false;
                                    deleteTechnician();
                                "></v-btn>
                            <v-btn @click="isActive.value = false" color="primary" rounded="lg">Não</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-container>
    </div>
    <!-- Desktop -->
    <div class="!tw-hidden xl:!tw-block">
        <v-navigation-drawer disable-route-watcher width="400" location="right" class="tw-bg-gray_lighter tw-p-4" floating mobile-breakpoint="xl" v-model="modelValue">
            <v-row>
                <v-col cols="12" class="tw-flex tw-flex-end tw-justify-end tw-mb-3">
                    <v-icon @click="modelValue = !modelValue" class="tw-text-primary">mdi-close</v-icon>
                </v-col>
                <v-col cols="12">
                    <img
                        class="tw-rounded-full tw-aspect-square tw-object-cover tw-w-36 tw-mx-auto tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
                        :src="useMedia(tech.picture, 'user').large"
                        alt="" />
                </v-col>
                <v-col cols="12">
                    <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center tw-mb-4">{{ tech.name }}</h1>
                    <h1 class="text-black tw-text-center">{{ tech.email }}</h1>
                </v-col>
                <v-col cols="12">
                    <span class="tw-my-4 tw-font-medium tw-text-lg">Infraestruturas associadas</span>
                    <div class="tw-relative tw-mb-8 tw-mt-2">
                        <div class="tw-flex tw-flex-col tw-items-center tw-gap-4">
                            <Card v-for="(infra, i) in infrastructures" :key="i" :title="infra.name" :image="useMedia(infra.picture, 'infrastructure').small" :arrow="false" />
                        </div>
                    </div>
                </v-col>
                <v-dialog width="500">
                    <template v-slot:activator="{ props }">
                        <v-btn :loading="isLoading" v-bind="props" class="tw-mx-auto" rounded="lg" color="red" size="large">Eliminar técnico</v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                        <v-card title="Eliminar técnico">
                            <v-card-text>
                                <span class="tw-text-lg">Tem a certeza que pretende eliminar este técnico?</span>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text="Sim"
                                    color="red"
                                    @click="
                                        isActive.value = false;
                                        deleteTechnician();
                                    "></v-btn>
                                <v-btn @click="isActive.value = false" color="primary" rounded="lg">Não</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-row>
        </v-navigation-drawer>
    </div>
</template>

<script setup lang="ts">
    import Card from './Layout/Card.vue';
    import Top from './Layout/Top.vue';
    import axios from '@/plugins/axios';
    import useMedia from '@/composables/useMedia';
    import { onMounted, ref, computed, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import qs from 'qs';
    import { useAppStore } from '@/store/app';

    const modelValue = defineModel({
        default: false,
    });

    const tech = ref({});
    const infrastructures = ref([]);
    const $route = useRoute();
    const $router = useRouter();
    const appStore = useAppStore();
    const isLoading = ref(false);

    const props = defineProps(['technicianId']);

    const query = {
        populate: {
            picture: true,
        },
    };

    const isMobile = computed(() => appStore.isMobile);

    const picture = computed(() => {
        return useMedia(tech.value.picture, 'tech');
    });

    const getTechnician = async () => {
        const techId = isMobile.value ? $route.params.id : props.technicianId;
        const res = await axios.get(`/users/${techId}?${qs.stringify(query)}`);
        tech.value = res.data;
    };

    const getInfrastructures = async () => {
        const techId = isMobile.value ? $route.params.id : props.technicianId;
        const res = await axios.get(`/infrastructures?filters[client][users][id][$eq]=${techId}`);
        infrastructures.value = res.data.data;
    };

    async function deleteTechnician() {
        isLoading.value = true;
        try {
            const techId = isMobile.value ? $route.params.id : props.technicianId;
            await axios.delete(`/users/${techId}`);
            if (isMobile.value) $router.push({ name: 'Profile' });
            if (!isMobile.value) $router.go(0);
        } catch (error) {
            console.log(error);
        } finally {
            isLoading.value = false;
        }
    }

    watch(
        () => appStore.isEditing,
        (newValue) => {
            if (newValue) {
                $router.push({ name: 'EditTechnician', params: { id: $route.params.id } });
            }
        },
    );

    watch(
        () => props.technicianId,
        async () => {
            if (props.technicianId) {
                await getTechnician();
                await getInfrastructures();
            }
        },
    );

    onMounted(async () => {
        await getTechnician();
        await getInfrastructures();
    });
</script>

<style scoped></style>
