<template>
    <!-- Mobile -->
    <div class="xl:!tw-hidden">
        <img class="tw-object-bottom tw-w-full tw-max-h-[25vh] tw-object-cover" src="../assets/svg/header_login.svg" alt="" />
        <img class="tw-absolute tw-left-8 tw-top-8 tw-w-40" src="/images/logo.png" alt="" />
        <v-container class="flex-column justify-start px-8" fluid>
            <v-form ref="form" class="tw-flex tw-flex-col tw-gap-5" @submit.prevent="login">
                <h1 class="tw-text-2xl text-primary tw-font-bold">Bem-vindo de volta</h1>
                <div class="tw-flex tw-flex-col tw-gap-4">
                    <label class="tw-text-black">Email</label>
                    <v-text-field hide-details="auto" v-model="email" :rules="[rules.required, rules.email]" required placeholder="Insira o seu email" type="email" variant="outlined"></v-text-field>
                </div>
                <div class="tw-flex tw-flex-col tw-gap-4">
                    <label class="tw-text-black">Password</label>
                    <v-text-field
                        hide-details="auto"
                        v-model="password"
                        :rules="[rules.required, (v) => rules.min(v, 6)]"
                        required
                        placeholder="Insira a password"
                        type="password"
                        variant="outlined"></v-text-field>
                </div>

                <router-link class="text-dark_green text-caption tw-underline" :to="{ name: 'ForgotPassword' }">Esqueceu-se da password?</router-link>
                <p v-if="errorMessage" class="tw-text-red">{{ errorMessage }}</p>
                <v-btn :loading="loading" width="110" type="submit" flat color="primary">Login</v-btn>
            </v-form>
        </v-container>
    </div>
    <!-- Desktop -->
    <v-main>
        <div class="!tw-hidden xl:!tw-block">
            <div class="tw-flex">
                <div class="tw-w-[70%] tw-h-[100vh]">
                    <div class="container-parent d-flex align-center justify-center tw-h-full">
                        <v-container>
                            <h1 class="login-title">Bem-vindo de volta</h1>
                            <v-form ref="form" @submit.prevent="login" class="login-form">
                                <div class="tw-flex tw-flex-col tw-gap-4">
                                    <label class="tw-text-black">Email</label>
                                    <v-text-field
                                        hide-details="auto"
                                        v-model="email"
                                        :rules="[rules.required, rules.email]"
                                        required
                                        placeholder="Insira o seu email"
                                        type="email"
                                        variant="outlined"></v-text-field>
                                </div>
                                <div class="tw-flex tw-flex-col tw-gap-4">
                                    <label class="tw-text-black">Password</label>
                                    <v-text-field
                                        hide-details="auto"
                                        v-model="password"
                                        :rules="[rules.required, (v) => rules.min(v, 6)]"
                                        required
                                        placeholder="Insira a password"
                                        type="password"
                                        variant="outlined"></v-text-field>
                                </div>
                                <router-link class="text-dark_green text-caption tw-underline" :to="{ name: 'ForgotPassword' }">Esqueceu-se da password?</router-link>
                                <p v-if="errorMessage" class="tw-text-red">{{ errorMessage }}</p>
                                <v-btn :loading="loading" width="110" type="submit" flat color="primary">Login</v-btn>
                            </v-form>
                        </v-container>
                    </div>
                </div>
                <div class="tw-w-[70%] tw-h-[100vh] tw-bg-primary tw-flex tw-justify-center">
                    <img class="tw-mx-auto tw-object-none" src="/images/logo.png" alt="" />
                </div>
            </div>
        </div>
    </v-main>

    <!-- <v-container v-if="false" class="flex-column justify-start px-8 py-8 tw-flex tw-flex-1 align-content-end" fluid>
        <v-row align="start" class="fill-width h-auto justify-start align-content-end">
            <v-col cols="12" class="">
                <v-btn @click="goToCompleteData" variant="tonal" class="text-none bg-pink"> Página completar dados</v-btn>
            </v-col>
        </v-row>
    </v-container> -->
</template>

<script lang="ts" setup>
    import { ref, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import axios from '../plugins/axios';
    import { useUserStore } from '@/store/user';
    import rules from '@/composables/rules';
    import qs from 'qs';

    const $router = useRouter();

    const email = ref('');
    const password = ref('');
    const form = ref<HTMLFormElement>();
    const loading = ref(false);

    const errorMessage = ref('');

    const login = async () => {
        if (!form.value) return;

        // reset validation
        form.value?.resetValidation();
        errorMessage.value = '';

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid, errors } = await form.value?.validate();

        if (!valid) return;

        loading.value = true;

        try {
            const res = await axios.post(
                `/auth/local?${qs.stringify({
                    populate: ['client', 'role', 'picture'],
                })}`,
                {
                    identifier: email.value,
                    password: password.value,
                },
            );

            useUserStore().setUser(res.data.user);
            useUserStore().setToken(res.data.jwt);

            $router.push({ name: 'Infrastructures' });
        } catch (e: any) {
            const error = e.response.data.error.message;
            if (error == 'Invalid identifier or password') {
                errorMessage.value = 'Credenciais inválidas';
            } else if (error == 'Your license is not valid') {
                errorMessage.value = 'A licença não é válida';
            } else if (error == 'Your license has expired') {
                errorMessage.value = 'A licença está expirada';
            } else {
                errorMessage.value = 'Ocorreu um erro';
            }
            loading.value = false;
        }
    };

    const goToCompleteData = () => {
        $router.push({ name: 'CompleteData' });
    };
</script>
<style scoped>
    .container-parent {
        max-width: min(90%, 35.25rem);
        margin-inline: auto;
    }
</style>
