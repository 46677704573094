<template>
    <!-- Mobile -->
    <div v-if="$route.name == 'Network'" class="xl:!tw-hidden">
        <Top :notifications="false" back :reverse="false" />
        <div class="tw-relative tw-mb-20 sm:tw-mb-14 -tw-translate-y-0.5">
            <img class="tw-object-bottom tw-object-cover" src="../../assets/svg/header_profile.png" alt="" />
            <img
                class="tw-rounded-full tw-aspect-square tw-object-cover tw-absolute tw-w-28 tw-bottom-0 tw-left-1/2 tw-translate-y-[71%] xs:tw-translate-y-[65%] sm:tw-translate-y-[44%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
                :src="waterNetworkIcon(network.type)"
                alt="" />
        </div>
        <v-container class="">
            <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ network.name }}</h1>
            <!-- text area for address -->
            <p class="tw-text-center tw-mt-2">{{ network.type }}</p>
            <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start mt-4" no-gutters>
                <template v-if="network.control_points && network.control_points.length > 0">
                    <v-col cols="12" v-for="(controlPoint, i) in network.control_points" :key="i">
                        <Card :image="useMedia(controlPoint.picture, 'control-point').small" :arrow="false" class="tw-mt-4" :title="pointT(controlPoint)" />
                    </v-col>
                </template>
                <template v-else>
                    <!-- no control points where found -->
                    <v-col cols="12">
                        <p class="tw-text-center tw-mt-8">Não foram encontrados pontos de controlo.</p>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </div>
    <!-- Desktop -->
    <div class="!tw-hidden xl:!tw-block">
        <v-navigation-drawer disable-route-watcher width="400" location="right" class="tw-bg-gray_lighter tw-p-4" floating mobile-breakpoint="xl" v-model="modelValue">
            <v-row>
                <v-col cols="12" class="tw-flex tw-flex-end tw-justify-end tw-mb-3">
                    <v-icon @click="modelValue = !modelValue" class="tw-text-primary">mdi-close</v-icon>
                </v-col>
                <v-col cols="12">
                    <img
                        class="tw-rounded-full tw-aspect-square tw-object-cover tw-w-36 tw-mx-auto tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
                        :src="waterNetworkIcon(network.type)"
                        alt="" />
                </v-col>
                <v-col cols="12">
                    <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center tw-mb-4">
                        {{ network.name }}
                    </h1>
                    <p class="tw-text-center tw-font-light tw-text-base">{{ network.type }}</p>
                </v-col>
                <v-col cols="12">
                    <template v-if="network.control_points && network.control_points.length > 0">
                        <v-col cols="12" v-for="(controlPoint, i) in network.control_points" :key="i">
                            <Card :image="useMedia(controlPoint.picture, 'control-point').small" :arrow="false" :title="pointT(controlPoint)" />
                        </v-col>
                    </template>
                    <template v-else>
                        <!-- no control points where found -->
                        <v-col cols="12">
                            <p class="tw-text-center tw-mt-8">Não foram encontrados pontos de controlo.</p>
                        </v-col>
                    </template>
                </v-col>
            </v-row>
        </v-navigation-drawer>
    </div>
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import { useRoute } from 'vue-router';
    import axios from '@/plugins/axios';
    import { ref, computed, onMounted, watch } from 'vue';
    import useMedia, { waterNetworkIcon } from '@/composables/useMedia';
    import { useAppStore } from '@/store/app';
    import Card from '../Layout/Card.vue';
    import { ControlPoint } from '../../types';

    const modelValue = defineModel({
        default: false,
    });

    const appStore = useAppStore();
    const $route = useRoute();

    const network = ref({});

    const props = defineProps(['networkId']);

    const isMobile = computed(() => {
        return appStore.isMobile;
    });

    const getNetwork = async () => {
        const networkId = isMobile.value ? $route.params.water_network_id : props.networkId;
        const res = await axios.get(`/water-networks/${networkId}?populate=control_points`);
        network.value = res.data.data;
    };

    onMounted(() => {
        getNetwork();
    });

    const pointT = (controlPoint: ControlPoint) => {
        return controlPoint.name + ' - ' + controlPoint.type;
    };

    watch(
        () => props.networkId,
        async () => {
            if (props.networkId) {
                getNetwork();
            }
        },
    );
</script>

<style scoped></style>
