<template>
    <!-- Mobile -->
    <div v-if="$route.name == 'CreateTechnician'" class="xl:!tw-hidden">
        <Top :back="true" :edit="false" :notifications="false" />
        <div class="tw-relative -tw-translate-y-0.5">
            <img class="tw-object-bottom tw-object-cover" src="../assets/svg/header_profile.png" alt="" />
            <h1 v-if="isAdding" class="tw-text-xl tw-text-white tw-mb-4 -tw-translate-y-[144%] xs:-tw-translate-y-[170%] tw-text-center">Criar técnico</h1>
            <h1 v-else class="tw-text-xl tw-text-white tw-mb-4 -tw-translate-y-[144%] xs:-tw-translate-y-[170%] tw-text-center">Editar técnico</h1>
        </div>
        <v-container class="flex-column justify-start tw-relative tw-flex tw-flex-1 !tw-pt-0" fluid>
            <v-form ref="form" @submit.prevent="createTechnician">
                <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start" no-gutters>
                    <v-col cols="10" class="">
                        <v-text-field hide-details="auto" v-model="name" :rules="[rules.required]" required placeholder="Nome do técnico" type="email" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col cols="10" class="mt-4">
                        <v-text-field hide-details="auto" v-model="email" :rules="[rules.required, rules.email]" required placeholder="Email" type="email" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col cols="10" class="tw-mt-10 tw-flex">
                        <h2 class="tw-w-fit tw-text-large tw-font-bold">Adicionar infraestruturas</h2>
                    </v-col>
                    <v-col cols="10 tw-mt-4">
                        <v-select :rules="[]" :items="infrastructures" item-title="name" hide-details="auto" clearable variant="outlined" v-model="selectedInfrastructures" item-value="id" multiple>
                            <template v-slot:prepend-item>
                                <v-list-item title="Todas as infraestruturas" @click="toggle">
                                    <template v-slot:prepend>
                                        <v-checkbox-btn
                                            :color="someInfrastructuresSelected ? 'primary' : undefined"
                                            :indeterminate="someInfrastructuresSelected && !allInfrastructuresSelected"
                                            :model-value="allInfrastructuresSelected"></v-checkbox-btn>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row align="end" justify="center" class="fill-width fill-height justify-center align-content-end mt-4" no-gutters>
                    <v-col cols="10" v-if="errorMessage">
                        <p class="tw-text-red tw-text-center tw-mb-4" v-html="errorMessage"></p>
                    </v-col>
                    <v-col cols="6">
                        <v-btn color="primary" :loading="loading" type="submit" block rounded="lg" size="large">Guardar</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </div>
    <!-- Desktop -->
    <div class="!tw-hidden xl:!tw-block">
        <v-navigation-drawer disable-route-watcher width="400" location="right" class="tw-bg-gray_lighter tw-p-4" floating mobile-breakpoint="xl" v-model="modelValue">
        <v-row>
            <v-col cols="12" class="tw-flex tw-flex-end tw-justify-end tw-mb-3">
                <v-icon @click="modelValue = !modelValue" class="tw-text-primary ">mdi-close</v-icon>
            </v-col>
            <v-col>
                <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center tw-mb-4">Criar técnico</h1>
            </v-col>
            <v-form ref="form" @submit.prevent="createTechnician">
                <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start" no-gutters>
                    <v-col cols="10" class="">
                        <v-text-field hide-details="auto" v-model="name" :rules="[rules.required]" required placeholder="Nome do técnico" type="email" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col cols="10" class="mt-4">
                        <v-text-field hide-details="auto" v-model="email" :rules="[rules.required, rules.email]" required placeholder="Email" type="email" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col cols="10" class="tw-mt-10 tw-flex">
                        <h2 class="tw-w-fit tw-text-large tw-font-bold">Adicionar infraestruturas</h2>
                    </v-col>
                    <v-col cols="10 tw-mt-4">
                        <v-select :rules="[]" :items="infrastructures" item-title="name" hide-details="auto" clearable variant="outlined" v-model="selectedInfrastructures" item-value="id" multiple>
                            <template v-slot:prepend-item>
                                <v-list-item title="Todas as infraestruturas" @click="toggle">
                                    <template v-slot:prepend>
                                        <v-checkbox-btn
                                            :color="someInfrastructuresSelected ? 'primary' : undefined"
                                            :indeterminate="someInfrastructuresSelected && !allInfrastructuresSelected"
                                            :model-value="allInfrastructuresSelected"></v-checkbox-btn>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row align="end" justify="center" class="fill-width fill-height justify-center align-content-end mt-4" no-gutters>
                    <v-col cols="10" v-if="errorMessage">
                        <p class="tw-text-red tw-text-center tw-mb-4" v-html="errorMessage"></p>
                    </v-col>
                    <v-col cols="6">
                        <v-btn color="primary" :loading="loading" type="submit" block rounded="lg" size="large">Guardar</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-row>
        </v-navigation-drawer>
    </div>
</template>

<script setup lang="ts">
    import Top from './Layout/Top.vue';
    import { watch, ref, onMounted, computed } from 'vue';
    import rules from '@/composables/rules';
    import axios from '@/plugins/axios';
    import { useRoute, useRouter } from 'vue-router';
    import { useUserStore } from '@/store/user';
    import { useAppStore } from '@/store/app';
    import _ from 'lodash';
    import qs from 'qs';

    const modelValue = defineModel({
        default: false,
    });

    const email = ref('');
    const name = ref('');
    const infrastructures = ref([]);
    const selectedInfrastructures = ref([]);
    const form = ref<HTMLFormElement>();
    const loading = ref(false);
    const $router = useRouter();
    const $route = useRoute();
    const userStore = useUserStore();
    const appStore = useAppStore();
    const errorMessage = ref('');

    const props = defineProps(['technicianId']);

    const getInfrastructures = async () => {
        const res = await axios.get(`/infrastructures?populate=client&sort=name&filters[client][users][id][$eq]=${userStore.getUser.id}`);
        infrastructures.value = res.data.data;
    };

    const me = computed(() => userStore.getUser);

    const isMobile = computed(() => {
        return appStore.isMobile;
    });

    const isAdding = computed(() => {
        return $route.name == 'CreateTechnician' || (!isMobile.value && !props.technicianId);
    });

    const allInfrastructuresSelected = computed(() => {
        return selectedInfrastructures.value.length === infrastructures.value.length;
    });

    const someInfrastructuresSelected = computed(() => {
        return selectedInfrastructures.value.length > 0;
    });

    const toggle = () => {
        if (allInfrastructuresSelected.value) {
            selectedInfrastructures.value = [];
        } else {
            selectedInfrastructures.value = infrastructures.value.slice();
        }
    };

    const createTechnician = async () => {
        if (!form.value) return;
        errorMessage.value = '';
        // reset validation
        form.value?.resetValidation();

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid, errors } = await form.value?.validate();
        if (!valid) return;

        loading.value = true;

        function generateRandomString(length = 16) {
            const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            return [...Array(length)].map(() => characters[Math.floor(Math.random() * characters.length)]).join('');
        }

        const randomString = generateRandomString();

        const data = {
            data: {
                name: name.value,
                email: email.value,
                username: email.value,
                ...(isAdding.value && { password: randomString }),
                role: 1,
                client: me.value.client.id,
                infrastructures: _.get(selectedInfrastructures.value, '0') == 'all' ? infrastructures.value.map((infra) => infra.id) : selectedInfrastructures.value.filter((infra) => infra !== null),
            },
        };

        try {
            if (isAdding.value) {
                const res = await axios.post('/users', data);
                if (!isMobile.value) $router.go(0);
            } else {
                const res = await axios.put(`/users/${$route.params.id}`, data);
            }
            $router.push({ name: 'Profile' });
        } catch (e: any) {
            let message = _.get(e, 'response.data.error.message', '');
            switch (message) {
                case 'Email already taken':
                    message = 'Este email já se encontra em uso.';
                    break;
                default:
                    message = 'Ocorreu um erro: <br>' + e.message;
                    break;
            }
            errorMessage.value = message;
        } finally {
            loading.value = false;
        }
    };

    async function fetchTechnician() {
        const query = {
            populate: ['infrastructures'],
        };
        const res = await axios.get(`/users/${$route.params.id}?${qs.stringify(query)}`);
        const technician = res.data;
        name.value = technician.name;
        email.value = technician.email;
        selectedInfrastructures.value = technician.infrastructures.map((infra) => infra.id);
    }

    onMounted(async () => {
        await getInfrastructures();
        if (!isAdding.value && isMobile.value) {
            await fetchTechnician();
        }
    });
</script>

<style lang="scss" scoped></style>
