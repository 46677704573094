import { useUserStore } from '@/store/user';
import { useRoute, useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import qs from 'qs';

export const useAuth = () => {
    const userStore = useUserStore();
    const $router = useRouter();
    const $route = useRoute();

    async function checkAuth() {
        if (userStore.getToken) {
            try {
                const query = {
                    populate: ['role','picture','client.users']
                }
                const { data } = await axios.get(`/users/me?${qs.stringify(query)}`);
                userStore.setUser(data);
            } catch (error) {
                userStore.$reset();
                const authRequired = $route.matched.some((record) => record.meta.auth);
                if (authRequired) {
                    $router.push({ name: 'Login' });
                }
            }
        }
    }

    return {
        checkAuth,
    };
};
