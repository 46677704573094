<template>
    <!-- Mobile -->
    <div v-if="$route.name == 'Inspection'" class="xl:!tw-hidden">
        <Top :back="true" :create="false" :reverse="false" :notifications="false" :edit="(textInspectionState == 'Por aprovar' || textInspectionState == 'Confirmado') && !isEditing" />
        <div class="tw-relative tw-mb-20 sm:tw-mb-14 -tw-translate-y-0.5">
            <img class="tw-object-bottom tw-object-cover" src="@/assets/svg/header_profile.png" alt="" />
            <div
                class="tw-bg-primary tw-flex tw-items-center tw-justify-center tw-rounded-full tw-aspect-square tw-absolute tw-w-36 -tw-bottom-1 tw-left-1/2 tw-translate-y-[76%] xs:tw-translate-y-[69%] sm:tw-translate-y-[57%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-white tw-overflow-hidden">
                <img v-if="!infrastructurePicture" class="tw-w-1/2 tw-h-1/2 tw-object-contain" src="@/assets/icons/calendar_inspections.svg" alt="" />
                <img v-else class="tw-w-full tw-h-full tw-object-cover" :src="infrastructurePicture" alt="" />
            </div>
        </div>
        <v-container class="d-flex flex-column align-center tw-gap-4">
            <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ inspection.infrastructure.name }}</h1>
            <p :class="colorInspectionSate" class="tw-rounded-md tw-p-1 tw-w-fit tw-text-regular tw-font-medium tw-text-white">{{ textInspectionState }}</p>
        </v-container>
        <v-container class="flex-column justify-start !tw-pt-2" fluid>
            <v-row align="center" class="fill-width h-auto justify-center align-content-center">
                <v-col cols="12">
                    <VueDatePicker :readonly="!isEditing" v-model="inspection.date" placeholder="Data e hora da inspeção" />
                </v-col>
                <v-col cols="12">
                    <v-textarea :readonly="!isEditing" v-model="inspection.observations" hide-details="auto" rows="10" label="Observações" placeholder="Observações" variant="outlined"></v-textarea>
                </v-col>
                <v-col v-if="isEditing" cols="auto" class="tw-order-1 tw-mt-8">
                    <v-btn @click="update" block size="large" flat color="primary">Guardar</v-btn>
                </v-col>
                <v-dialog v-if="textInspectionState == 'Por aprovar' || textInspectionState == 'Confirmado'" width="500">
                    <template v-slot:activator="{ props }">
                        <v-btn :loading="isLoading" v-bind="props" rounded="lg" color="red" size="large" class="tw-mt-8">Cancelar inspeção</v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                        <v-card title="Cancelar inspeção">
                            <v-card-text>
                                <span class="tw-text-lg">Tem a certeza que pretende cancelar esta inspeção?</span>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text="Sim"
                                    color="red"
                                    @click="
                                        isActive.value = false;
                                        cancel();
                                    "></v-btn>
                                <v-btn @click="isActive.value = false" color="primary" rounded="lg">Não</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-col v-if="inspection.files !== null" cols="6">
                    <a target="_blank" :href="downloadFileUrl" download>
                        <v-btn block size="large" flat color="primary">Download</v-btn>
                    </a>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <!-- Desktop -->
    <div class="!tw-hidden xl:!tw-block">
        <v-navigation-drawer disable-route-watcher width="400" location="right" class="tw-bg-gray_lighter tw-p-4" floating mobile-breakpoint="xl" v-model="modelValue">
            <v-row>
                <v-col cols="12" class="tw-flex tw-flex-end tw-justify-end tw-mb-3">
                    <v-icon @click="onClose" class="tw-text-primary">mdi-close</v-icon>
                </v-col>
                <v-col cols="12">
                    <img v-if="!infrastructurePicture" class="tw-rounded-full tw-aspect-square tw-object-cover tw-w-36 tw-mx-auto" src="@/assets/icons/calendar_inspections.svg" alt="" />
                    <img v-else class="tw-rounded-full tw-aspect-square tw-object-cover tw-w-36 tw-mx-auto" :src="infrastructurePicture" alt="" />
                </v-col>
                <v-col cols="12">
                    <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center tw-mb-4">{{ inspection.infrastructure.name }}</h1>
                    <p :class="colorInspectionSate" class="tw-rounded-md tw-mx-auto tw-p-1 tw-w-fit tw-text-regular tw-font-medium tw-text-white">{{ textInspectionState }}</p>
                </v-col>
                <v-col cols="12">
                    <VueDatePicker :readonly="!isEditing" v-model="inspection.date" placeholder="Data e hora da inspeção" />
                </v-col>
                <v-col cols="12">
                    <v-textarea :readonly="!isEditing" v-model="inspection.observations" hide-details="auto" rows="10" label="Observações" placeholder="Observações" variant="outlined"></v-textarea>
                </v-col>
                <v-btn v-if="isEditing" class="tw-mx-auto tw-order-1 tw-mt-4" @click="update" size="large" flat color="primary">Guardar</v-btn>
                <v-dialog v-if="textInspectionState == 'Por aprovar' || textInspectionState == 'Confirmado'" width="500">
                    <template v-slot:activator="{ props }">
                        <v-btn :loading="isLoading" v-bind="props" class="tw-mx-auto tw-mt-4" rounded="lg" color="red" size="large">Cancelar inspeção</v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                        <v-card title="Cancelar inspeção">
                            <v-card-text>
                                <span class="tw-text-lg">Tem a certeza que pretende cancelar esta inspeção?</span>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text="Sim"
                                    color="red"
                                    @click="
                                        isActive.value = false;
                                        cancel();
                                    "></v-btn>
                                <v-btn @click="isActive.value = false" color="primary" rounded="lg">Não</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-btn
                    v-if="(textInspectionState == 'Por aprovar' || textInspectionState == 'Confirmado') && !isEditing"
                    :loading="isLoading"
                    v-bind="props"
                    class="tw-mx-auto tw-mt-4"
                    rounded="lg"
                    color="primary"
                    size="large"
                    @click="onEditDesktop()"
                    >Editar</v-btn
                >
                <v-col v-if="inspection.files !== null" cols="12" class="tw-flex tw-justify-center">
                    <a target="_blank" :href="downloadFileUrl" download>
                        <v-btn size="large" flat color="primary">Download</v-btn>
                    </a>
                </v-col>
            </v-row>
        </v-navigation-drawer>
    </div>
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import { ref, onMounted, computed, watch } from 'vue';
    import axios from '@/plugins/axios';
    import { useRoute, useRouter } from 'vue-router';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import useMedia from '@/composables/useMedia';
    import _ from 'lodash';
    import { useAppStore } from '../../store/app';
    import { on } from 'events';

    const modelValue = defineModel({
        default: false,
    });

    const $route = useRoute();
    const $router = useRouter();
    const appStore = useAppStore();
    const isLoading = ref(false);

    const inspection = ref({ infrastructure: { name: '' }, status: '', date: '', observations: '', files: null });

    const props = defineProps(['inspectionId']);

    const isEditing = computed(() => appStore.isEditing);

    const isMobile = computed(() => appStore.isMobile);

    const getInspection = async () => {
        const inspectionId = isMobile.value ? $route.params.idInspection : props.inspectionId;
        const res = await axios.get(`/inspections/${inspectionId}?populate=infrastructure.picture,files`);
        inspection.value = res.data.data;
    };

    const colorInspectionSate = computed(() => {
        switch (inspection.value.status) {
            case 'concluído':
            case 'confirmado':
                return 'tw-bg-green';
            case 'negado':
            case 'cancelado':
                return 'tw-bg-red';
            default:
                return 'tw-bg-yellow';
        }
    });

    const infrastructurePicture = computed(() => {
        return useMedia(_.get(inspection.value, 'infrastructure.picture'), 'infrastructure').small;
    });

    const textInspectionState = computed(() => {
        switch (inspection.value.status) {
            case 'concluído':
                return 'Concluída';
            case 'confirmado':
                return 'Confirmado';
            case 'negado':
                return 'Negado';
            case 'cancelado':
                return 'Cancelada';
            default:
                return 'Por aprovar';
        }
    });

    const cancel = async () => {
        isLoading.value = true;
        try {
            await axios.put(`/inspections/${inspection.value.id}`, { data: { status: 'cancelado' } });
            if (isMobile.value) $router.push({ name: 'Inspections' });
            if (!isMobile.value) $router.go(0);
        } catch (error) {
            console.log(error);
        } finally {
            isLoading.value = false;
        }
    };

    const downloadFileUrl = computed(() => {
        return import.meta.env.VITE_API_URL + '' + inspection.value.files[0].url;
    });

    const update = async () => {
        const inspectionId = isMobile.value ? $route.params.idInspection : props.inspectionId;
        await axios.put(`/inspections/${inspectionId}`, { data: inspection.value });
        if (isMobile.value) $router.push({ name: 'Inspections' });
        if (!isMobile.value) $router.go(0);
    };

    const onEditDesktop = () => {
        appStore.setEditing(true);
    };

    const onClose = () => {
        appStore.setEditing(false);
        modelValue.value = !modelValue.value;
    };

    watch(
        () => props.inspectionId,
        async () => {
            if (props.inspectionId) {
                appStore.setEditing(false);
                await getInspection();
            }
        },
    );

    onMounted(() => {
        getInspection();
    });
</script>

<style scoped></style>
