<template>
    <div class="xl:!tw-hidden">
        <Top :back="true" :create="false" :reverse="false" :notifications="false" />
        <div class="tw-relative tw-mb-20 sm:tw-mb-14 -tw-translate-y-0.5">
            <img class="tw-object-bottom tw-object-cover" src="../../assets/svg/header_profile.png" alt="" />
            <img
                class="tw-rounded-full tw-aspect-square tw-object-cover tw-absolute tw-w-28 tw-bottom-0 tw-left-1/2 tw-translate-y-[71%] xs:tw-translate-y-[65%] sm:tw-translate-y-[44%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
                :src="useMedia(infrastructure.picture, 'infrastructure').original"
                alt="" />
        </div>
    </div>
    <v-loading></v-loading>
    <v-container class="!tw-hidden xl:!tw-block !tw-pt-9 !tw-px-16 !tw-pl-[76.8px]">
        <v-row class="tw-items-center tw-gap-x-6">
            <img class="tw-rounded-lg tw-outline-1 tw-aspect-square tw-object-cover tw-w-28" :src="useMedia(infrastructure.picture, 'infrastructure').original" alt="" />
            <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ infrastructure.name }}</h1>
        </v-row>
    </v-container>
    <v-container class="xl:!tw-pt-0 xl:!tw-px-16">
        <h1 class="xl:!tw-hidden tw-text-2xl text-black tw-font-bold tw-text-center">{{ infrastructure.name }}</h1>
        <!-- text area for address -->
        <p class="tw-text-center tw-mt-2">{{ infrastructure.address }}</p>
        <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start" no-gutters>
            <template v-if="infrastructure.water_networks && infrastructure.water_networks.length > 0">
                <v-col cols="12" v-for="(network, i) in infrastructure.water_networks" :key="i">
                    <Card :image="waterNetworkIcon(network.type)" class="tw-mt-4" :title="network.name" @click="goToNetwork(network)" />
                </v-col>
            </template>
            <template v-else>
                <!-- no water networks where found -->
                <v-col cols="12">
                    <p class="tw-text-center tw-mt-8">Não foram encontradas redes de água.</p>
                </v-col>
            </template>
        </v-row>
    </v-container>
    <Network v-if="openNetworkSidebar" v-model="openNetworkSidebar" :networkId="networkId" />
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import Card from '../Layout/Card.vue';
    import { ref, computed } from 'vue';
    import axios from '@/plugins/axios';
    import { useRoute, useRouter } from 'vue-router';
    import useMedia, { waterNetworkIcon } from '@/composables/useMedia';
    import { useAppStore } from '@/store/app';
    import Network from './Network.vue';

    const appStore = useAppStore();

    let infrastructure = ref({});

    const $route = useRoute();
    const $router = useRouter();
    const url = import.meta.env.VITE_API_URL;

    const openNetworkSidebar = ref(false);
    const networkId = ref('');

    const isMobile = computed(() => {
        return appStore.isMobile;
    });

    const getInfrastructure = async () => {
        const res = await axios.get(`/infrastructures/${$route.params.infrastucture_id}?populate=*`);

        infrastructure.value = res.data.data;
    };

    const goToNetwork = (network: any) => {
        if (isMobile.value) {
            $router.push({ name: 'Network', params: { infrastucture_id: $route.params.infrastucture_id, water_network_id: network.id } });
        } else {
            // If the sidebar is already open, simply update the network info
            if (openNetworkSidebar.value) {
                networkId.value = network.id;
            } else {
                // If it's not open, open the sidebar and update the network info
                openNetworkSidebar.value = true;
                networkId.value = network.id;
            }
        }
    };
    getInfrastructure();
</script>

<style scoped></style>
