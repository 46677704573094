<template>
    <v-navigation-drawer color="primary" width="300" :v-model="true" rail-width="80" :rail="rail" permanent @click="rail = false">
        <div class="tw-flex tw-h-full tw-flex-col tw-justify-between">
            <div>
                <v-list-item nav>
                    <template v-if="!rail">
                        <v-img class="tw-m-auto tw-block tw-cursor-pointer tw-py-8" @click="$router.push({ name: 'Infrastructures' })" width="120" src="@/assets/logo2.png" alt="Corsar Logo"></v-img>
                    </template>
                    <template v-slot:append>
                        <v-btn
                            class="tw-m-4 tw-mx-auto tw-flex tw-w-[61px] tw-text-xl tw-text-primary tw-mr-[6px]"
                            :icon="rail ? 'mdi-menu' : 'mdi-chevron-left'"
                            variant="text"
                            @click.stop="rail = !rail"></v-btn>
                    </template>
                </v-list-item>
                <!-- <v-divider></v-divider> -->
                <v-list bg-color="primary" base-color="primary_ligther" :class="{ 'custom-sidebar': rail, 'tw-px-6': !rail }" class="v-list-mb tw-items-center tw-justify-center" density="compact" nav>
                    <v-list-item title="Infraestruturas" :to="{ name: 'Infrastructures' }">
                        <template v-slot:prepend>
                            <v-icon>
                                <img class="tw--rotate-45" src="/icons/push-pin.svg" alt="" />
                            </v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item title="Leituras" :to="{ name: 'Readings' }">
                        <template v-slot:prepend>
                            <v-icon>
                                <img src="/icons/push-pin.svg" alt="" />
                            </v-icon> </template
                    ></v-list-item>
                    <v-list-item title="Análises" :to="{ name: 'Analysis' }">
                        <template v-slot:prepend>
                            <v-icon>
                                <img src="/icons/profit.svg" alt="" />
                            </v-icon> </template
                    ></v-list-item>
                    <v-list-item title="Inspeções" :to="{ name: 'Inspections' }">
                        <template v-slot:prepend>
                            <v-icon>
                                <img src="/icons/calendar.svg" alt="" />
                            </v-icon> </template
                    ></v-list-item>
                    <v-list-item prepend-icon="mdi-file-document" title="Documentos" :to="{ name: 'Documents' }"> </v-list-item>
                    <v-list-item prepend-icon="mdi-bell" title="Notificações" :to="{ name: 'Notifications' }">
                        <template v-slot:append>
                            <v-badge :model-value="notificationsNumber > 0" :content="notificationsNumber" color="error" inline></v-badge>
                        </template>
                    </v-list-item>
                </v-list>
            </div>

            <v-list class="!tw-bg-primary_dark !tw-text-white tw-text-base tw-text-left tw-py-6 tw-px-3" bg-color="tw-text-secondary-light">
                <v-list-item>
                    <div class="tw-flex tw-items-center tw-justify-between">
                        <img class="tw-rounded-full tw-w-10 tw-object-cover tw-cursor-pointer" @click="$router.push({ name: 'Profile' })" :src="picture.small" alt="" />
                        <div>
                            <p>{{ me?.name ? me?.name : me?.username }}</p>
                            <p class="tw-text-xs">{{ me?.email }}</p>
                        </div>
                        <v-list-item-icon class="!tw-text-primary_lighter_2 tw-cursor-pointer" @click="logout()">
                            <v-icon> mdi-logout </v-icon>
                        </v-list-item-icon>
                    </div>
                </v-list-item>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { ref, watch, onBeforeUnmount, onMounted, computed } from 'vue';
    import { useAppStore } from '@/store/app';
    import { useUserStore } from '@/store/user';
    import { useRouter } from 'vue-router';
    import { useDisplay } from 'vuetify/lib/framework.mjs';
    import axios from '@/plugins/axios';
    import useMedia from '@/composables/useMedia';
    import qs from 'qs';
    import { useSocket } from '@/composables/useSocket';

    const { lgAndUp } = useDisplay();

    const appStore = useAppStore();
    const userStore = useUserStore();
    const $router = useRouter();

    const rail = ref(false);

    const me = ref(useUserStore().getUser);

    let notificationsNumber = ref(0);

    let socket = useSocket();
    // subscribe to the notifications channel
    socket.on('notifications_number', (data) => {
        notificationsNumber.value = data.data;
    });

    const isMobile = computed(() => {
        return !lgAndUp.value;
    });

    const query = ref({
        sort: ['read:asc', 'createdAt:desc'],
        filters: {
            user: {
                id: {
                    $eq: userStore.getUser.id,
                },
            },
            read: {
                $eq: false,
            },
        },
        pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
        },
    });

    const getNotifications = async () => {
        const res = await axios.get(`notifications/?${qs.stringify(query.value)}`);
        notificationsNumber.value = res.data.meta.pagination.total;
    };

    async function logout() {
        userStore.$reset();
        $router.push({ name: 'Login' });
    }

    const picture = computed(() => {
        return useMedia(me.value.picture, 'user');
    });

    watch(isMobile, (value) => {
        if (value) (rail.value = true), appStore.setIsSidebarOpen(false);
        else (rail.value = false), appStore.setIsSidebarOpen(true);
    });

    onBeforeUnmount(() => {
        appStore.setIsSidebarOpen(false);
    });

    onMounted(() => {
        getNotifications();
    });
</script>

<style>
    .logo img,
    .v-navigation-drawer__content img {
        display: block !important;
    }
    .v-navigation-drawer .v-list-group__items {
        height: auto !important;
        --indent-padding: 0.3125rem !important;
    }
    .v-navigation-drawer .custom-sidebar .v-list-group__items .v-list-item {
        max-width: 65px;
        padding-right: 9px !important;
        padding-inline-start: calc(3px + var(--indent-padding)) !important;
    }
    .v-navigation-drawer .custom-sidebar {
        padding-left: 21.5px !important;
        max-width: 68px !important;
        /* margin-bottom: 10px !important; */
    }
    .v-navigation-drawer .v-list-mb .v-list-item--nav:not(:only-child) {
        margin-bottom: 14px !important;
    }
</style>
