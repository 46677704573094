<template>
    <!-- Mobile -->
    <div v-if="$route.name == 'Schedule'" class="xl:!tw-hidden">
        <Top :back="true" :create="false" :reverse="false" :notifications="false" :absolute="false" />
        <div class="tw-relative -tw-translate-y-0.5">
            <img class="tw-object-bottom tw-object-cover" src="@/assets/svg/header_profile.png" alt="" />
            <h1 class="tw-text-xl tw-text-white tw-mb-4 -tw-translate-y-[152%] xs:-tw-translate-y-[170%] tw-text-center">Agendar inspeção</h1>
        </div>
        <v-form ref="form" @submit.prevent="submit">
            <v-container class="tw-flex tw-flex-col tw-gap-4 justify-start !tw-pt-0" fluid>
                <div>
                    <v-select
                        :rules="[rules.required]"
                        hide-details="auto"
                        clearable
                        variant="outlined"
                        placeholder="Todas as infraestrutura"
                        item-value="id"
                        item-title="name"
                        v-model="schedule.infrastructure"
                        :items="infrastructures"></v-select>
                </div>
                <div>
                    <VueDatePicker v-model="schedule.date" placeholder="Selecionar data e hora" />
                </div>
                <div>
                    <v-textarea v-model="schedule.observations" variant="outlined" hide-details="auto" rows="10" label="Observações" placeholder="Observações"></v-textarea>
                </div>
                <v-btn position="fixed" class="tw-bottom-16 !tw-min-w-[12rem] tw-left-1/2 -tw-translate-x-1/2" type="submit" :loading="loading" size="large" flat color="primary">Agendar</v-btn>
            </v-container>
        </v-form>
    </div>
    <!-- Desktop -->
    <div class="!tw-hidden xl:!tw-block">
        <v-navigation-drawer disable-route-watcher width="400" location="right" class="tw-bg-gray_lighter tw-p-4 " floating mobile-breakpoint="xl" v-model="modelValue">
            <v-row>
                <v-col cols="12" class="tw-flex tw-flex-end tw-justify-end tw-mb-3">
                    <v-icon @click="modelValue = !modelValue" class="tw-text-primary">mdi-close</v-icon>
                </v-col>
                <v-col>
                    <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center tw-mb-4">Agendar inspeção</h1>
                </v-col>
                <v-form ref="form" @submit.prevent="submit">
                    <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start" no-gutters>
                        <v-col cols="10">
                            <v-select
                                :rules="[rules.required]"
                                hide-details="auto"
                                clearable
                                variant="outlined"
                                placeholder="Todas as infraestrutura"
                                item-value="id"
                                item-title="name"
                                v-model="schedule.infrastructure"
                                :items="infrastructures"></v-select>
                        </v-col>
                        <v-col cols="10" class="tw-mt-4">
                            <VueDatePicker v-model="schedule.date" placeholder="Selecionar data e hora" />
                        </v-col>
                        <v-col cols="10" class="tw-mt-4">
                            <v-textarea v-model="schedule.observations" variant="outlined" hide-details="auto" rows="10" label="Observações" placeholder="Observações"></v-textarea>
                        </v-col>
                        <v-btn position="fixed" class="tw-bottom-16 !tw-min-w-[12rem] tw-left-1/2 -tw-translate-x-1/2" type="submit" :loading="loading" size="large" flat color="primary"
                            >Agendar</v-btn
                        >
                    </v-row>
                </v-form>
            </v-row>
        </v-navigation-drawer>
    </div>
</template>

<script setup lang="ts">
    import Top from '../Layout/TopBar.vue';
    import { ref, onMounted, computed } from 'vue';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import { useRouter } from 'vue-router';
    import rules from '@/composables/rules';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import { Infrastructure } from '../../types';
    import { useUserStore } from '@/store/user';
    import { useAppStore } from '@/store/app';

    const modelValue = defineModel({
        default: false,
    });

    const userStore = useUserStore();
    const appStore = useAppStore();

    const schedule = ref({
        date: '',
        observations: '',
        infrastructure: null,
    });

    const infrastructures = ref([]);
    const form = ref<HTMLFormElement>();
    const loading = ref(false);
    const $router = useRouter();

    const isMobile = computed(() => appStore.isMobile);

    const getInfrastructures = async () => {
        const query = {
            filters: {
                ...(userStore.isTechnician
                    ? {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      }
                    : {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client.id,
                              },
                          },
                      }),
            },
            sort: ['name:asc'],
        };
        const res = await axios.get(`/infrastructures?${qs.stringify(query)}`);
        infrastructures.value = res.data.data;
    };

    const submit = async () => {
        if (!form.value) return;

        // reset validation
        form.value?.resetValidation();

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid, errors } = await form.value?.validate();

        if (!valid) return;

        console.log(schedule.value);

        loading.value = true;

        await axios.post(`/inspections`, { data: schedule.value }).then((res) => {
            if (isMobile.value) $router.push({ name: 'Inspections' });
            if (!isMobile.value) $router.go(0);
            loading.value = false;
        });
    };

    onMounted(() => {
        getInfrastructures();
    });
</script>

<style scoped></style>
