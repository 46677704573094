<template>
    <div class="xl:!tw-hidden">
        <Top title="Documentos"  />
    </div>
    <v-container class="flex-column justify-start tw-flex -tw-translate-y-0.5 xl:!tw-gap-4 xl:-tw-translate-y-0 xl:!tw-pt-9 xl:!tw-px-16">
        <h1 class="!tw-hidden xl:!tw-block tw-mr-auto tw-text-2xl tw-font-bold">Documentos</h1>
        <v-row class="fill-width h-auto tw-justify-center tw-content-center xl:tw-justify-start xl:tw-align-content-start">
            <v-col cols="11" xl="12" class="tw-mt-3">
                <Card
                    :arrow="false"
                    :download="true"
                    v-for="(doc, i) in documents"
                    class="tw-mt-4 !tw-p-3"
                    :title="doc.document.name"
                    :imageMIME="useMediaExt(doc.document.ext)"
                    :point="moment(doc.updatedAt).format('DD/MM/YYYY HH:MM')"
                    @click="getDocument(doc)"
                    :key="i" />
                <div v-intersect="onIntersectDocuments"></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import Top from './Layout/Top.vue';
    import Card from './Layout/Card.vue';
    import axios from '@/plugins/axios';
    import useMediaExt from '@/composables/useMediaExt';
    import { ref, onMounted } from 'vue';
    import qs from 'qs';
    import { useUserStore } from '@/store/user';
    import moment from 'moment';


    const documents = ref([]);
    const userStore = useUserStore();

    const query = ref({
            filters: {
                cliente: {
                    id: {
                        $eq: userStore.getUser.client?.id,
                    },
                },
            },
            populate: ['document', 'cliente'],
            pagination: {
                page: 1,
                pageSize: 25,
            },
        });

    const onIntersectDocuments = (isIntersecting: any, entries: any, observer: any) => {
        if (isIntersecting && query.value.pagination.page < query.value.pagination.pageCount) {
            query.value.pagination.page++;
            getDocuments();
        }
    };

    const getDocuments = async () => {
        const res = await axios.get(`/documents?${qs.stringify(query.value)}`);
        query.value.pagination = res.data.meta.pagination;
        documents.value = query.value.pagination.page == 1 ? res.data.data : [...documents.value, ...res.data.data];
    };

    const getDocument = async (doc: { document: { url: string } }) => {
        window.open(`${import.meta.env.VITE_API_URL}${doc.document.url}`);
    };

    onMounted(() => {
        getDocuments();
    });
</script>

<style scoped></style>
