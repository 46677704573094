<template>
    <!-- Mobile -->
    <div v-if="$route.name == 'CreateReading'" class="xl:!tw-hidden">
        <Top :back="true" :notifications="false" :absolute="true" :reverse="false" />
        <div class="tw-relative tw-mb-20 sm:tw-mb-14 -tw-translate-y-0.5">
            <img class="tw-object-bottom tw-object-cover" src="../../assets/svg/header_profile.png" alt="" />
            <img
                class="tw-rounded-full tw-aspect-square tw-object-cover tw-absolute tw-w-28 tw-bottom-0 tw-left-1/2 tw-translate-y-[71%] xs:tw-translate-y-[65%] sm:tw-translate-y-[44%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
                :src="image"
                alt="" />
        </div>
        <v-container class="flex-column justify-start tw-relative tw-flex tw-flex-1" fluid>
            <v-row align="start" class="fill-width justify-center tw-bg-white tw-pb-4 !tw-flex-shrink-0 !tw-flex-grow-0" no-gutters>
                <v-col cols="11"
                    ><h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ reading.control_point.water_network.infrastructure.name }}</h1></v-col
                >
                <v-col cols="11" class="mt-2">
                    <h1 class="tw-text-xl text-black tw-text-center">{{ reading.control_point.water_network.name }}</h1>
                </v-col>
                <v-col cols="11" class="mt-2">
                    <div class="tw-w-fit tw-m-auto tw-bg-gray tw-px-6 tw-py-1 tw-rounded-md">
                        <p class="tw-w-fit tw-text-sm tw-text-primary tw-font-semibold">{{ reading.control_point.name }}</p>
                    </div>
                </v-col>
            </v-row>
            <Cold :idReadingSet="idReadingSet" v-if="reading.control_point.water_network.type == 'fria'" :readingtype="reading.reading_type" />
            <Hot :idReadingSet="idReadingSet" v-else-if="reading.control_point.water_network.type == 'quente'" :readingtype="reading.reading_type" />
            <Pool :idReadingSet="idReadingSet" v-else-if="reading.control_point.water_network.type == 'piscina'" :readingtype="reading.reading_type" />
        </v-container>
    </div>
    <!-- Desktop -->
    <div class="!tw-hidden xl:!tw-block">
        <v-navigation-drawer disable-route-watcher width="400" location="right" class="tw-bg-gray_lighter tw-p-4 " floating mobile-breakpoint="xl" v-model="modelValue">
            <v-row>
                <v-col cols="12" class="tw-flex tw-flex-end tw-justify-end tw-mb-3">
                    <v-icon @click="modelValue = !modelValue" class="tw-text-primary">mdi-close</v-icon>
                </v-col>
                <v-col cols="12">
                    <img
                        class="tw-rounded-full tw-aspect-square tw-object-cover tw-w-36 tw-mx-auto tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
                        :src="image"
                        alt="" />
                </v-col>
                <v-col cols="12">
                    <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ reading.control_point.water_network.infrastructure.name }}</h1>
                </v-col>
                <v-col cols="12">
                    <h1 class="tw-text-2xl text-black tw-text-center tw-mb-4">{{ reading.control_point.water_network.name }}</h1>
                    <div class="tw-w-fit tw-m-auto tw-bg-gray tw-px-6 tw-py-1 tw-rounded-md">
                        <p class="tw-w-fit tw-text-sm tw-text-primary tw-font-semibold">{{ reading.control_point.name }}</p>
                    </div>
                </v-col>
                <v-col cols="12">
                    <Cold :idReadingSet="idReadingSet" v-if="reading.control_point.water_network.type == 'fria'" :readingtype="reading.reading_type" />
                    <Hot :idReadingSet="idReadingSet" v-else-if="reading.control_point.water_network.type == 'quente'" :readingtype="reading.reading_type" />
                    <Pool :idReadingSet="idReadingSet" v-else-if="reading.control_point.water_network.type == 'piscina'" :readingtype="reading.reading_type" />
                </v-col>
            </v-row>
        </v-navigation-drawer>
    </div>
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import Hot from './Forms/Hot.vue';
    import Cold from './Forms/Cold.vue';
    import Pool from './Forms/Pool.vue';
    import axios from '@/plugins/axios';
    import { useRoute } from 'vue-router';
    import { onMounted, ref, computed, watch } from 'vue';
    import useMedia from '@/composables/useMedia';
    import { useAppStore } from '@/store/app';

    const modelValue = defineModel({
        default: false,
    });

    const appStore = useAppStore();

    const isMobile = computed(() => appStore.isMobile);

    const $route = useRoute();

    const props = defineProps(['readingId']);

    const idReadingSet = isMobile.value ? ref($route?.params?.id) : ref(props.readingId);

    const reading = ref({ control_point: { picture: null, name: '', water_network: { name: '', type: '', infrastructure: { name: '' } } }, reading_type: '' });

    const getReadingSet = async () => {
        const readingId = isMobile.value ? idReadingSet.value : props.readingId;
        const res = await axios.get(`/reading-sets/${readingId}?populate=control_point.picture,control_point,control_point.water_network,control_point.water_network.infrastructure`);
        reading.value = res.data.data;
    };


    const image = computed(() => {
        return useMedia(reading.value.control_point.picture, 'control-point').large;
    });

    watch(
        () => props.readingId,
        async () => {
            if (props.readingId) {
                idReadingSet.value = props.readingId;
                getReadingSet();
            }
        },
    );

    onMounted(() => {
        getReadingSet();
    });
</script>

<style scoped></style>
