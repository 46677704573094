export default {
    colors: {
        transparent: 'transparent',
        current: 'currentColor',
        white: '#ffffff',
        black: '#333333',
        primary_lightest: '#ECECF4',
        primary_lighter: '#cccedf',
        primary_lighter_2: '#bbbcd9',
        primary_light: '#777AB3',
        primary: '#454997',
        primary_dark: '#2B2E7A',

        // gray: {
        gray_lightest: '#FAFAFF',
        gray_lighter: "#F3F3F8",
        gray_light: '#F1F1F1',
        gray: '#ECECF4',
        gray_dark: '#CCCCCC',
        gray_darker: '#ACACAC',

        //     dark: "#CCCCCC",
        //     darker: "#ACACAC"
        // },
        orange: '#FF8000',
        red: '#EB5656',
        green: '#39A762',
        pink: '#DA369E',
        yellow: '#FFC107',
    },
} as any;
