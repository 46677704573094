<template>
    <div class="xl:!tw-hidden">
        <Top title="Infraestruturas" :notifications="!userStore.isTechnician" />
    </div>
    <v-container class="flex-column justify-start tw-flex -tw-translate-y-0.5 xl:!tw-gap-4 xl:-tw-translate-y-0 xl:!tw-pt-9 xl:!tw-px-16">
        <h1 class="!tw-hidden xl:!tw-block tw-mr-auto tw-text-2xl tw-font-bold">Infraestruturas</h1>
        <v-row class="fill-width h-auto tw-justify-center tw-content-center xl:tw-justify-start xl:tw-align-content-start">
            <v-col cols="11" xl="12" class="tw-mt-3">
                <InputText v-model="search" class="tw-mb-4" placeholder="Pesquisar" />
                <Card
                    v-for="(infra, i) in infrastructures"
                    class="tw-mt-4"
                    :title="infra.name"
                    :image="useMedia(infra.picture, 'infrastructure').thumbnail"
                    :to="{ name: 'Infrastructure', params: { infrastucture_id: infra.id } }"
                    :key="i" />
                <div v-intersect="onIntersectInfraestructures"></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import InputText from '../Layout/Forms/InputText.vue';
    import Card from '../Layout/Card.vue';
    import axios from '@/plugins/axios';
    import useMedia from '@/composables/useMedia';
    import { ref, watch, computed, onMounted } from 'vue';
    import qs from 'qs';
    import { useUserStore } from '@/store/user';

    const infrastructures = ref([]);
    const search = ref('');
    const userStore = useUserStore();

    const query = ref({
            sort: ['name'],
            filters: {
                ...(search.value && {
                    name: {
                        $containsi: search.value,
                    },
                }),
                ...(userStore.isTechnician
                    ? {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      }
                    : {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client?.id,
                              },
                          },
                      }),
            },
            populate: 'picture',
            pagination: {
                page: 1,
                pageSize: 25,
            },
        });

    const onIntersectInfraestructures = (isIntersecting: any, entries: any, observer: any) => {
        if (isIntersecting && query.value.pagination.page < query.value.pagination.pageCount) {
            query.value.pagination.page++;
            getInfrastructures();
        }
    };

    const getInfrastructures = async () => {
        const res = await axios.get(`/infrastructures?${qs.stringify(query.value)}`);
        query.value.pagination = res.data.meta.pagination;
        infrastructures.value = query.value.pagination.page == 1 ? res.data.data : [...infrastructures.value, ...res.data.data];
    };

    watch(search, () => {
        query.value.filters.name = { $containsi: search.value };
        query.value.pagination.page = 1;
        getInfrastructures();
    });

    onMounted(() => {
        getInfrastructures();
    });
</script>

<style scoped></style>
