<template>
    <div class="xl:!tw-hidden">
        <Top :notifications="!userStore.isTechnician" />
    </div>
    <div class="xl:!tw-hidden tw-relative tw-mb-20 sm:tw-mb-14 -tw-translate-y-0.5">
        <img class="tw-object-bottom tw-object-cover" src="../assets/svg/header_profile.png" alt="" />
        <img
            class="tw-rounded-full tw-aspect-square tw-object-cover tw-absolute tw-w-28 tw-bottom-0 tw-left-1/2 tw-translate-y-[71%] xs:tw-translate-y-[65%] sm:tw-translate-y-[44%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
            :src="picture.large"
            alt="" />
    </div>
    <v-container class="!tw-hidden xl:!tw-block !tw-pt-9 !tw-px-16 !tw-pl-[76.8px]">
        <v-row class="tw-items-center tw-gap-x-6">
            <img class="tw-rounded-full tw-aspect-square tw-object-cover tw-w-28" :src="picture.large" alt="" />
            <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ me.name ? me.name : me.username }}</h1>
        </v-row>
    </v-container>
    <v-container class="xl:!tw-hidden !tw-pb-0 !tw-pt-4">
        <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ me.name ? me.name : me.username }}</h1>
    </v-container>
    <v-container v-if="!userStore.isTechnician" class="!tw-py-2 tw-sticky tw-top-[var(--topBarHeight)] tw-z-10 tw-bg-white xl:!tw-pt-9 xl:!tw-px-16">
        <v-row>
            <v-col cols="6" xl="3">
                <v-btn @click="changeView('profile')" block variant="tonal" size="large" rounded="xl" :class="activeComponentComp == 'profile' ? 'bg-primary_light' : 'bg-gray_light'" class="text-none"
                    >Perfil</v-btn
                >
            </v-col>
            <v-col cols="6" xl="3">
                <v-btn
                    v-if="!userStore.isTechnician"
                    @click="changeView('technicals')"
                    block
                    variant="tonal"
                    size="large"
                    rounded="xl"
                    :class="activeComponentComp == 'technicals' ? 'bg-primary_light' : 'bg-gray_light'"
                    class="text-none"
                    >Técnicos</v-btn
                >
            </v-col>
            <v-col offset="1" class="!tw-hidden xl:!tw-block tw-text-end" v-if="me.client.users.length < me.client.max_technicians && activeComponent == 'technicals'">
                <v-btn @click="goToCreateTechnician" variant="tonal" size="large" rounded="lg" class="bg-primary text-none">Criar Técnico</v-btn>
            </v-col>
        </v-row>
    </v-container>
    <v-container class="!tw-py-4 xl:!tw-pt-9 xl:!tw-px-16">
        <div v-if="activeComponentComp == 'profile'" class="xl:tw-grid xl:tw-grid-cols-2 tw-gap-4 tw-flex tw-flex-col">
            <InputText class="tw-w-full" disabled placeholder="Nome da organização" :modelValue="client.name" variant="solo" />
            <InputText class="tw-w-full" disabled placeholder="NIF" :modelValue="client.nif" variant="solo" />
            <InputText class="tw-w-full" disabled placeholder="Morada" :modelValue="client.address" variant="solo" />
            <InputText class="tw-w-full" disabled placeholder="Email" :modelValue="me.email" variant="solo" />
            <InputText class="tw-w-full" disabled placeholder="Telefone" :modelValue="client.phone" variant="solo" />

            <div class="xl:!tw-hidden tw-col-span-2 d-flex">
                <v-btn class="mx-auto" @click="logout" width="110" type="submit" flat color="red">Logout</v-btn>
            </div>
            <div class="xl:!tw-hidden tw-col-span-2 tw-mt-4">
                <p class="tw-text-center tw-text-gray_darker tw-text-sm">v{{ appVersion }}</p>
            </div>
        </div>

        <div v-if="activeComponentComp == 'technicals' && !userStore.isTechnician" class="tw-flex tw-flex-col tw-items-center tw-gap-4">
            <Card v-for="tech in me.client.users" :key="tech.id" :title="tech.name" :point="tech.email" :image="useMedia(tech.picture, 'user').large" @click="goToTechnician(tech)" />
            <div v-if="me.client.users.length < me.client.max_technicians" class="xl:!tw-hidden tw-mt-8">
                <v-btn @click="goToCreateTechnician" variant="tonal" size="large" rounded="lg" class="bg-primary text-none">Criar Técnico</v-btn>
            </div>
        </div>
    </v-container>
    <CreateTechnician v-if="openCreateTechnicianSidebar" v-model="openCreateTechnicianSidebar" :technicianId="technicianId" />
    <Technician v-if="openTechnicianSidebar" v-model="openTechnicianSidebar" :technicianId="technicianId" />
</template>

<script setup lang="ts">
    import { computed, ref, onMounted, watch } from 'vue';
    import Card from './Layout/Card.vue';
    import CreateTechnician from './CreateTechnician.vue';
    import Technician from './Technician.vue';
    import Top from './Layout/Top.vue';
    import InputText from './Layout/Forms/InputText.vue';
    import { useRouter } from 'vue-router';
    import { useUserStore } from '@/store/user';
    import { useAppStore } from '@/store/app';
    import axios from '@/plugins/axios';
    import useMedia from '@/composables/useMedia';
    import qs from 'qs';

    const $router = useRouter();
    const appStore = useAppStore();
    const userStore = useUserStore();

    const openCreateTechnicianSidebar = ref(false);
    const openTechnicianSidebar = ref(false);
    const technicianId = ref(null);

    const appVersion = import.meta.env.VITE_APP_VERSION;

    let activeComponent = ref('profile');

    const me = ref({ client: { users: [] }, picture: null });

    const activeComponentComp = computed(() => {
        if (activeComponent.value == 'profile') openCreateTechnicianSidebar.value = false;
        return activeComponent.value;
    });

    const isMobile = computed(() => {
        return appStore.isMobile;
    });

    const changeView = (view: string) => {
        activeComponent.value = view;
    };

    const goToTechnician = (technician: any) => {
        if (isMobile.value) {
            $router.push({ name: 'Technician', params: { id: technician.id } });
        } else {
            // Close the create technician sidebar if it's open
            openCreateTechnicianSidebar.value = false;
            // If the sidebar is already open, simply update the technician info
            if (openTechnicianSidebar.value) {
                technicianId.value = technician.id;
            } else {
                // If it's not open, open the sidebar and update the technician info
                openTechnicianSidebar.value = true;
                technicianId.value = technician.id;
            }
        }
    };

    const goToCreateTechnician = () => {
        if (isMobile.value) {
            $router.push({ name: 'CreateTechnician' });
        } else {
            // Close the technician sidebar if it's open
            openTechnicianSidebar.value = false;
            // If the sidebar is already open, reset technician info
            if (openCreateTechnicianSidebar.value) {
                technicianId.value = null;
            } else {
                // If it's not open, open the sidebar and reset the technician info
                openCreateTechnicianSidebar.value = true;
                technicianId.value = null;
            }
        }
    };

    const logout = async () => {
        userStore.$reset();
        $router.push({ name: 'Login' });
    };

    const query = ref({
        populate: {
            client: {
                populate: {
                    users: {
                        filters: {
                            role: {
                                name: {
                                    $eq: 'Technician',
                                },
                            },

                            id: {
                                $ne: userStore.getUser.id,
                            },
                        },
                        populate: {
                            picture: true,
                        },
                    },
                },
            },
            picture: true,
        },
    });

    const getMe = async () => {
        const res = await axios.get(`/users/me?${qs.stringify(query.value)}`);
        me.value = res.data;
    };

    const client = computed(() => {
        return me.value.client;
    });

    const picture = computed(() => {
        return useMedia(me.value.picture, 'user');
    });

    onMounted(() => {
        getMe();
    });
</script>

<style scoped></style>
